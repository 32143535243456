// store.ts
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import snackbarReducer from './slices/snackbarSlice';
import garageReducer from './slices/garageSlice';
import userReducer from './slices/userSlice';

// Configuration de redux-persist
const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['garage', 'user'], // Si vous souhaitez seulement persister certains slices
};

// Combine les reducers
const rootReducer = combineReducers({
  snackbar: snackbarReducer,
  garage: garageReducer,
  user: userReducer,
});

// Utilisez persistReducer pour rendre les reducers persistants
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
});

// Crée un persistor
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
