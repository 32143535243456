import {
  type Garage,
  Logger,
  MovaAppType,
  SubscriptionPaymentInterval,
  type User,
  UserService,
} from '@movalib/movalib-commons';
import { readCookie } from '@movalib/movalib-commons/dist/src/helpers/CookieUtils';
import { flexCenter, flexStart } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import {
  Alert,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  type SxProps,
  Tabs,
  TextField,
  type Theme,
  Typography,
  darken,
  useTheme,
} from '@mui/material';
import type React from 'react';
import { type FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import LogoProLarge from '../../assets/images/logo/logo_pro_large_border.png';
import { COOKIE_PRO_TOKEN } from '../../helpers/Constants';
import { StyledTab } from '../../helpers/Styled';
import { flexEnd } from '../../helpers/Tools';
import { setSnackbar } from '../../slices/snackbarSlice';

interface AdditionalEmployeePricing {
  basePrice: number;
  annualPrice: number;
  biennialPrice: number;
}

const subscriptionPricing = {
  startPack: {
    basePrice: 185.0,
    annualPrice: 149.0, // (19,45% de remise)
    biennialPrice: 129.0, // (30,27% de remise)
  },
  additionalEmployees: {
    one: {
      basePrice: 123.0,
      annualPrice: 99.0, // (19,51% de remise)
      biennialPrice: 89.0, // (27,64% de remise)
    },
    two: {
      basePrice: 111.0,
      annualPrice: 89.0, // (19,82% de remise)
      biennialPrice: 79.0, // (28,82% de remise)
    },
    more: {
      basePrice: 86.0,
      annualPrice: 69.0, // (19,77% de remise)
      biennialPrice: 59.0, // (31,39% de remise)
    },
  },
  smsOperatorUnit: {
    baseQuantity: 250,
  },
};

const TAX_RATE = 0.2;

interface PricingTableProps {
  defaultWorkforce?: number;
  onWorkforceChange?: (value: number) => void;
  defaultSubscription?: SubscriptionPaymentInterval;
  onWSubscriptionChange?: (value: SubscriptionPaymentInterval) => void;
  pushSubscriptionCost?: (value: number) => void;
  sx?: SxProps<Theme>;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

enum SubscriptionTabs {
  ALLOWANCE = 0,
  GAIN = 1,
  LAUNCH_OFFER = 2,
}

interface SubscriptionPricing {
  basePrice: number;
  annualPrice: number;
  biennialPrice: number;
}

const PricingTable: FunctionComponent<PricingTableProps> = ({
  defaultWorkforce = 1,
  onWorkforceChange,
  defaultSubscription = SubscriptionPaymentInterval.YEAR,
  onWSubscriptionChange,
  pushSubscriptionCost,
  sx,
}) => {
  const isAuthenticated: boolean = Boolean(readCookie(COOKIE_PRO_TOKEN));
  const [connectedUser, setConnectedUser] = useState<User | undefined>();
  const [registeredGarages, setRegisteredGarages] = useState<Garage[] | []>();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const [workforce, setWorkforce] = useState<number>(defaultWorkforce);
  const [subscriptionPayment, setSubscriptionPayment] = useState<SubscriptionPaymentInterval>(defaultSubscription);
  const [dailyInvoices, setDailyInvoices] = useState<number>(6);
  const [tabValue, setTabValue] = useState<SubscriptionTabs>(SubscriptionTabs.ALLOWANCE);
  const [starterPackCost, setStarterPackCost] = useState<number>();
  const [techniciansCost, setTechniciansCost] = useState<number>();
  const [subscriptionCost, setSubscriptionCost] = useState<number>();

  // Chargement initial de la page
  useEffect(() => {
    if (isAuthenticated) {
      // On contrôle que l'utilisateur dispose du rôle adéquat
      UserService.getCurrentUser(MovaAppType.GARAGE).then((response) => {
        if (response.success) {
          setConnectedUser(response.data);
          refreshRegisteredGarages();
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? "Chargement de votre compte impossible, contactez l'équipe Movalib",
              severity: 'error',
            }),
          );
        }
      });
    }
  }, [location.search]);

  // Chargement initial de la page
  useEffect(() => {
    refreshRegisteredGarages();
  }, [connectedUser]);

  useEffect(() => {
    refreshSubscriptionCost();
    // Appeler la fonction et mettre à jour l'état avec le nouveau coût
    setTechniciansCost(getAdditionnalTechnicianCost());
  }, [workforce]);

  useEffect(() => {
    refreshSubscriptionCost();
  }, [subscriptionPayment]);

  const refreshRegisteredGarages = () => {
    if (connectedUser) {
      // Chargement des inscriptions de garage rattachées au profil
      UserService.getSalesGarages(connectedUser.id).then((response) => {
        if (response.success) {
          Logger.info(response.data);
          setRegisteredGarages(response.data);
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? "Chargement des inscriptions impossible, contactez l'équipe Movalib",
              severity: 'error',
            }),
          );
        }
      });
    }
  };

  const getStarterPackCost = (): number => {
    switch (subscriptionPayment) {
      case SubscriptionPaymentInterval.MONTH:
        return subscriptionPricing.startPack.basePrice;
      case SubscriptionPaymentInterval.YEAR:
        return subscriptionPricing.startPack.annualPrice;
      case SubscriptionPaymentInterval.TWO_YEARS:
        return subscriptionPricing.startPack.biennialPrice;
    }

    return 0;
  };

  const getAdditionnalTechnicianCost = (): number => {
    if (workforce === 2) {
      switch (subscriptionPayment) {
        case SubscriptionPaymentInterval.MONTH:
          return subscriptionPricing.additionalEmployees.one.basePrice;
        case SubscriptionPaymentInterval.YEAR:
          return subscriptionPricing.additionalEmployees.one.annualPrice;
        case SubscriptionPaymentInterval.TWO_YEARS:
          return subscriptionPricing.additionalEmployees.one.biennialPrice;
      }
    }
    if (workforce === 3) {
      switch (subscriptionPayment) {
        case SubscriptionPaymentInterval.MONTH:
          return subscriptionPricing.additionalEmployees.two.basePrice;
        case SubscriptionPaymentInterval.YEAR:
          return subscriptionPricing.additionalEmployees.two.annualPrice;
        case SubscriptionPaymentInterval.TWO_YEARS:
          return subscriptionPricing.additionalEmployees.two.biennialPrice;
      }
    }
    if (workforce >= 4) {
      switch (subscriptionPayment) {
        case SubscriptionPaymentInterval.MONTH:
          return subscriptionPricing.additionalEmployees.more.basePrice;
        case SubscriptionPaymentInterval.YEAR:
          return subscriptionPricing.additionalEmployees.more.annualPrice;
        case SubscriptionPaymentInterval.TWO_YEARS:
          return subscriptionPricing.additionalEmployees.more.biennialPrice;
      }
    }

    return 0;
  };

  const refreshSubscriptionCost = () => {
    setStarterPackCost(getStarterPackCost());
    setTechniciansCost(getAdditionnalTechnicianCost());
    setSubscriptionCost(() => {
      // Tarif de base : starter pack + coût par réparateur
      const cost = (workforce - 1) * getAdditionnalTechnicianCost();
      const totalCost = getStarterPackCost() + cost;

      // On transmet au callback si présent
      if (pushSubscriptionCost) {
        pushSubscriptionCost(totalCost);
      }
      return totalCost;
    });
  };

  const handleWorkforceChange = (value: number) => {
    // Callback calling if available
    if (onWorkforceChange) {
      onWorkforceChange(value);
    }

    setWorkforce(value);
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 3, px: 2 }}>{children}</Box>}
      </div>
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const getRdvRatioCost = (): number => {
    if (subscriptionCost) {
      return subscriptionCost / (dailyInvoices * 20);
    }
    return 0;
  };

  const handleSubscriptionChange = (e: SelectChangeEvent<string>): void => {
    const fieldValue: SubscriptionPaymentInterval = e.target.value as SubscriptionPaymentInterval;

    // Si engagement mensuel on force la valeur du tabValue à 1 (onglet Amortissement)
    if (fieldValue === SubscriptionPaymentInterval.MONTH && tabValue === SubscriptionTabs.LAUNCH_OFFER) {
      setTabValue(SubscriptionTabs.ALLOWANCE);
    }

    // Callback calling if available
    if (onWSubscriptionChange) {
      onWSubscriptionChange(fieldValue);
    }

    setSubscriptionPayment(fieldValue);
  };

  return (
    <Box sx={{ ...sx, height: '100%' }}>
      <Grid container style={flexCenter}>
        <Grid item xs={6} style={flexCenter} sx={{ pt: 2 }}>
          <img src={LogoProLarge} style={{ width: '50%' }} />
        </Grid>
        <Grid item xs={6}>
          <Alert
            severity='success'
            icon={<br />}
            style={flexCenter}
            sx={{ width: '100%', textTransform: 'none', textAlign: 'center', px: 1 }}
          >
            <b>VOTRE ABONNEMENT</b>
            <br />
            <Typography variant='body2' style={flexCenter} sx={{ mt: 1 }}>
              Une offre complète !
            </Typography>
          </Alert>
        </Grid>
        <Grid item xs={12} sx={{ mt: 3, pr: 3 }}>
          <Grid container>
            <Grid item xs={12}>
              <FormControl fullWidth margin='normal' required size='small'>
                <InputLabel id='payment-interval-label'>ENGAGEMENT</InputLabel>
                <Select
                  labelId='payment-interval-label'
                  id='subscriptionPaymentInterval'
                  name='subscriptionPaymentInterval'
                  size='small'
                  label='ENGAGEMENT'
                  value={subscriptionPayment}
                  onChange={(e) => handleSubscriptionChange(e)}
                >
                  <MenuItem value={SubscriptionPaymentInterval.MONTH}>
                    <b>Mensuel&nbsp;</b>{' '}
                  </MenuItem>
                  <MenuItem value={SubscriptionPaymentInterval.YEAR}>
                    <b>Annuel&nbsp;</b>&nbsp;&nbsp;(20% de remise)
                  </MenuItem>
                  <MenuItem value={SubscriptionPaymentInterval.TWO_YEARS}>
                    <b>24 Mois&nbsp;</b>&nbsp;&nbsp;(30% de remise)
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <TextField
                id='settings-workforce'
                label='NOMBRE DE RÉPARATEURS'
                value={workforce}
                type='number'
                required
                size='small'
                onChange={(e) => handleWorkforceChange(Math.max(1, Number(e.target.value)))}
                helperText="Indiquez l'effectif à temps plein"
                sx={{ width: '90%', mt: 2.5 }}
                InputProps={{ inputProps: { min: 1 } }}
              />
            </Grid>
            <Grid item xs={7} style={flexEnd}>
              <TextField
                id='daily-appointments'
                label='FACTURES JOUR'
                value={dailyInvoices}
                type='number'
                required
                size='small'
                onChange={(e) => {
                  setTabValue(SubscriptionTabs.ALLOWANCE);
                  setDailyInvoices(Math.max(1, Number(e.target.value)));
                }}
                helperText='Nombre de factures / jour'
                sx={{ width: '90%', mt: 2.5 }}
                InputProps={{ inputProps: { min: 1 } }}
              />
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 3 }}>
            <Grid item xs={8}>
              <Typography variant='h6' component='h3' style={{ color: theme.palette.text.secondary }}>
                Starter Pack (pour 1 réparateur)
              </Typography>
            </Grid>
            <Grid item xs={4} style={flexEnd}>
              <Typography
                variant='h6'
                component='h2'
                style={{ fontWeight: 'bold', color: theme.palette.text.secondary }}
              >
                {starterPackCost &&
                  new Intl.NumberFormat('fr-FR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(starterPackCost)}{' '}
                €{' '}
                <span
                  style={{
                    marginLeft: 5,
                    fontWeight: 'normal',
                    color: theme.palette.text.secondary,
                    fontSize: '0.9rem',
                  }}
                >
                  HT
                </span>
              </Typography>
              <Typography variant='h6' component='h3' style={{ marginLeft: 5, color: theme.palette.text.secondary }}>
                /mois
              </Typography>
            </Grid>
            {workforce > 1 && (
              <>
                <Grid item xs={8}>
                  <Typography variant='h6' component='h3' style={{ color: theme.palette.text.secondary }}>
                    Réparateur supplémentaire (x {workforce - 1})
                  </Typography>
                </Grid>
                <Grid item xs={4} style={flexEnd}>
                  <Typography
                    variant='h6'
                    component='h2'
                    style={{ fontWeight: 'bold', color: theme.palette.text.secondary }}
                  >
                    {techniciansCost &&
                      new Intl.NumberFormat('fr-FR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(techniciansCost)}{' '}
                    €{' '}
                    <span
                      style={{
                        marginLeft: 5,
                        fontWeight: 'normal',
                        color: theme.palette.text.secondary,
                        fontSize: '0.9rem',
                      }}
                    >
                      HT
                    </span>
                  </Typography>
                  <Typography
                    variant='h6'
                    component='h3'
                    style={{ marginLeft: 5, color: theme.palette.text.secondary }}
                  >
                    /mois
                  </Typography>
                </Grid>
              </>
            )}
            <Grid container sx={{ mt: 1 }}>
              {workforce > 1 && (
                <>
                  <Grid item xs={8}>
                    <Typography
                      variant='h6'
                      component='h3'
                      style={{ color: theme.palette.text.secondary, fontSize: '1.1rem' }}
                    >
                      + Module de gestion des réparateurs
                    </Typography>
                  </Grid>
                  <Grid item xs={4} style={flexEnd}>
                    <Typography
                      variant='h6'
                      component='h3'
                      style={{ marginLeft: 5, color: theme.palette.text.secondary, fontSize: '1.1rem' }}
                    >
                      inclus
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={8}>
                <Typography
                  variant='h6'
                  component='h3'
                  style={{ color: theme.palette.text.secondary, fontSize: '1.1rem' }}
                >
                  + Forfait {workforce * subscriptionPricing.smsOperatorUnit.baseQuantity} SMS
                </Typography>
              </Grid>
              <Grid item xs={4} style={flexEnd}>
                <Typography
                  variant='h6'
                  component='h3'
                  style={{ marginLeft: 5, color: theme.palette.text.secondary, fontSize: '1.1rem' }}
                >
                  inclus
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  variant='h6'
                  component='h3'
                  style={{ color: theme.palette.text.secondary, fontSize: '1.1rem' }}
                >
                  + Identification véhicule (SIV)
                </Typography>
              </Grid>
              <Grid item xs={4} style={flexEnd}>
                <Typography
                  variant='h6'
                  component='h3'
                  style={{ marginLeft: 5, color: theme.palette.text.secondary, fontSize: '1.1rem' }}
                >
                  inclus
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <Typography
                  variant='h6'
                  component='h3'
                  style={{ color: theme.palette.text.secondary, fontSize: '1.1rem' }}
                >
                  + Mise à jour fiche Google MyBusiness
                </Typography>
              </Grid>
              <Grid item xs={4} style={flexEnd}>
                <Typography
                  variant='h6'
                  component='h3'
                  style={{ marginLeft: 5, color: theme.palette.text.secondary, fontSize: '1.1rem' }}
                >
                  inclus
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  variant='h6'
                  component='h3'
                  style={{ color: theme.palette.text.secondary, fontSize: '1.1rem' }}
                >
                  + Import du fichier clients
                </Typography>
              </Grid>
              <Grid item xs={4} style={flexEnd}>
                <Typography
                  variant='h6'
                  component='h3'
                  style={{ marginLeft: 5, color: theme.palette.text.secondary, fontSize: '1.1rem' }}
                >
                  inclus
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  variant='h6'
                  component='h3'
                  style={{ color: theme.palette.text.secondary, fontSize: '1.1rem' }}
                >
                  + Prise de rendez-vous via QR Code personnalisé
                </Typography>
              </Grid>
              <Grid item xs={4} style={flexEnd}>
                <Typography
                  variant='h6'
                  component='h3'
                  style={{ marginLeft: 5, color: theme.palette.text.secondary, fontSize: '1.1rem' }}
                >
                  inclus
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  variant='h6'
                  component='h3'
                  style={{ color: theme.palette.text.secondary, fontSize: '1.1rem' }}
                >
                  + Création des affiches garage (PLV)
                </Typography>
              </Grid>
              <Grid item xs={4} style={flexEnd}>
                <Typography
                  variant='h6'
                  component='h3'
                  style={{ marginLeft: 5, color: theme.palette.text.secondary, fontSize: '1.1rem' }}
                >
                  inclus
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  variant='h6'
                  component='h3'
                  style={{ color: theme.palette.text.secondary, fontSize: '1.1rem' }}
                >
                  + Référencement des fournisseurs
                </Typography>
              </Grid>
              <Grid item xs={4} style={flexEnd}>
                <Typography
                  variant='h6'
                  component='h3'
                  style={{ marginLeft: 5, color: theme.palette.text.secondary, fontSize: '1.1rem' }}
                >
                  inclus
                </Typography>
              </Grid>
            </Grid>

            <Box sx={{ borderTop: `1px solid ${theme.palette.primary.main}`, width: '100%', mt: 2 }} style={flexStart}>
              <Grid item xs={8}>
                <Typography variant='h6' component='h3' style={{ color: theme.palette.text.secondary }}>
                  Total abonnement
                  {(subscriptionPayment === SubscriptionPaymentInterval.YEAR ||
                    subscriptionPayment === SubscriptionPaymentInterval.TWO_YEARS) &&
                    ' (1 mois offert)'}
                </Typography>
              </Grid>
              <Grid item xs={4} style={flexEnd}>
                <Typography
                  variant='h6'
                  component='h2'
                  style={{ fontWeight: 'bold', color: theme.palette.text.secondary }}
                >
                  {subscriptionPayment === SubscriptionPaymentInterval.YEAR ||
                  subscriptionPayment === SubscriptionPaymentInterval.TWO_YEARS ? (
                    <>
                      {Intl.NumberFormat('fr-FR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(0)}{' '}
                      €{' '}
                      <span
                        style={{
                          marginLeft: 5,
                          fontWeight: 'normal',
                          color: theme.palette.text.secondary,
                          fontSize: '0.9rem',
                        }}
                      >
                        HT
                      </span>
                    </>
                  ) : (
                    <>
                      {subscriptionCost &&
                        new Intl.NumberFormat('fr-FR', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(subscriptionCost)}{' '}
                      €{' '}
                      <span
                        style={{
                          marginLeft: 5,
                          fontWeight: 'normal',
                          color: theme.palette.text.secondary,
                          fontSize: '0.9rem',
                        }}
                      >
                        HT
                      </span>
                    </>
                  )}
                </Typography>
                <Typography variant='h6' component='h3' style={{ marginLeft: 5, color: theme.palette.text.secondary }}>
                  /mois
                </Typography>
              </Grid>
            </Box>

            {(subscriptionPayment === SubscriptionPaymentInterval.YEAR ||
              subscriptionPayment === SubscriptionPaymentInterval.TWO_YEARS) && (
              <>
                <Grid item xs={8}>
                  <Typography variant='h6' component='h3' style={{ color: theme.palette.text.secondary }}>
                    Puis
                  </Typography>
                </Grid>
                <Grid item xs={4} style={flexEnd}>
                  <Typography
                    variant='h6'
                    component='h2'
                    style={{ fontWeight: 'bold', color: theme.palette.text.secondary }}
                  >
                    {subscriptionCost &&
                      new Intl.NumberFormat('fr-FR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(subscriptionCost)}{' '}
                    €{' '}
                    <span
                      style={{
                        marginLeft: 5,
                        fontWeight: 'normal',
                        color: theme.palette.text.secondary,
                        fontSize: '0.9rem',
                      }}
                    >
                      HT
                    </span>
                  </Typography>
                  <Typography
                    variant='h6'
                    component='h3'
                    style={{ marginLeft: 5, color: theme.palette.text.secondary }}
                  >
                    /mois
                  </Typography>
                </Grid>
              </>
            )}
            <>
              <Grid item xs={8} />
              <Grid item xs={4} style={flexEnd} sx={{ mt: 0.5 }}>
                <Typography style={{ fontSize: '1rem', color: theme.palette.text.secondary }}>
                  (&nbsp;
                  {subscriptionCost &&
                    new Intl.NumberFormat('fr-FR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(subscriptionCost + subscriptionCost * TAX_RATE)}{' '}
                  €{' '}
                  <span
                    style={{
                      marginLeft: 5,
                      fontWeight: 'normal',
                      color: theme.palette.text.secondary,
                      fontSize: '0.9rem',
                    }}
                  >
                    TTC
                  </span>
                </Typography>
                <Typography style={{ marginLeft: 5, fontSize: '1.1rem', color: theme.palette.text.secondary }}>
                  /mois )
                </Typography>
              </Grid>
            </>
          </Grid>

          <Grid container style={flexCenter} sx={{ mt: 3 }}>
            <Box sx={{ width: '100%' }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  variant='fullWidth'
                  sx={{ width: '100%' }}
                  aria-label='basic tabs example'
                  centered
                >
                  <StyledTab label={<span>🪙&nbsp;&nbsp;Amortissement</span>} {...a11yProps(0)} />
                  <StyledTab label={<span>📈&nbsp;&nbsp;Croissance CA / mois</span>} {...a11yProps(1)} />
                  <StyledTab
                    label={<span>🎉&nbsp;&nbsp;Offre de lancement</span>}
                    {...a11yProps(2)}
                    disabled={subscriptionPayment === SubscriptionPaymentInterval.MONTH}
                    sx={{
                      textDecoration:
                        subscriptionPayment === SubscriptionPaymentInterval.MONTH ? 'line-through' : 'none',
                    }}
                  />
                </Tabs>
              </Box>

              {/* Amortissement */}
              <CustomTabPanel value={tabValue} index={SubscriptionTabs.ALLOWANCE}>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  p={2}
                  boxShadow={2}
                  borderRadius={2}
                  style={{
                    maxWidth: 350,
                    margin: 'auto',
                    marginTop: 10,
                    border: `2px solid ${darken(theme.palette.primary.main, 0.2)}`,
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} style={flexCenter}>
                      <Typography
                        variant='h5'
                        component='h2'
                        style={{ color: darken(theme.palette.text.secondary, 0.4) }}
                      >
                        {subscriptionPayment === SubscriptionPaymentInterval.QUARTER && <span>🎉&nbsp;</span>}

                        <b>
                          {' '}
                          {subscriptionCost &&
                            new Intl.NumberFormat('fr-FR', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(getRdvRatioCost())}{' '}
                          €
                        </b>
                      </Typography>
                      <Typography
                        variant='h6'
                        component='h3'
                        style={{ marginLeft: 5, color: darken(theme.palette.text.secondary, 0.4) }}
                      >
                        /rdv <b>*</b>
                        {subscriptionPayment === SubscriptionPaymentInterval.QUARTER && (
                          <Typography variant='h4' component='h2' sx={{ display: 'contents' }}>
                            &nbsp; 🎉
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box style={flexCenter} sx={{ mt: 4, mb: 1 }}>
                  <Alert sx={{ borderRadius: 20 }} icon={<span />} className='styled-alert'>
                    * Coût de revient estimé pour <b>{dailyInvoices * 20}</b> factures par mois.
                  </Alert>
                </Box>
              </CustomTabPanel>

              {/* Gains perçus */}
              <CustomTabPanel value={tabValue} index={SubscriptionTabs.GAIN}>
                <Grid container spacing={1} style={flexCenter}>
                  {/* MO */}
                  <Grid item xs={4} padding={2}>
                    <Typography
                      variant='h6'
                      style={flexCenter}
                      sx={{ color: theme.palette.text.secondary, fontSize: '1.2rem' }}
                    >
                      <b>MO</b>
                    </Typography>
                    <Box sx={{ border: `3px solid ${'#F29ABA'}`, m: 1, py: 1 }} p={2} boxShadow={3} borderRadius={2}>
                      <Typography
                        variant='h6'
                        sx={{
                          color: theme.palette.text.secondary,
                          mb: 0.5,
                          borderBottom: `1px dashed ${darken(theme.palette.primary.main, 0.3)}`,
                          mx: 3,
                          fontSize: '1.6rem',
                        }}
                        style={flexCenter}
                      >
                        <b>4 h</b>
                      </Typography>
                      <Typography
                        variant='h6'
                        sx={{
                          color: '#F29ABA',
                          mb: 0.5,
                          fontFamily: 'caveat',
                          fontSize: '1.9rem',
                          fontWeight: 'bold',
                        }}
                        style={flexCenter}
                      >
                        300 € / mois
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant='subtitle2'
                        style={flexCenter}
                        sx={{
                          color: theme.palette.text.secondary,
                          textAlign: 'center',
                          fontSize: '0.8rem',
                        }}
                      >
                        <i>taux moyen 75 € HT / h</i>
                      </Typography>
                    </Box>
                  </Grid>
                  {/* Amortissement */}
                  <Grid item xs={4} padding={2}>
                    <Typography
                      variant='h6'
                      style={flexCenter}
                      sx={{ color: theme.palette.text.secondary, fontSize: '1.2rem' }}
                    >
                      <b>DEVIS</b>
                    </Typography>
                    <Box
                      sx={{ border: `3px solid ${theme.palette.secondary.main}`, m: 1, py: 1 }}
                      p={2}
                      boxShadow={2}
                      borderRadius={2}
                    >
                      <Typography
                        variant='h6'
                        sx={{
                          color: theme.palette.text.secondary,
                          mb: 0.5,
                          borderBottom: `1px dashed ${darken(theme.palette.primary.main, 0.3)}`,
                          mx: 3,
                          fontSize: '1.6rem',
                        }}
                        style={flexCenter}
                      >
                        <b>2</b>
                      </Typography>
                      <Typography
                        variant='body2'
                        sx={{
                          color: darken(theme.palette.secondary.main, 0.1),
                          mb: 0.5,
                          fontFamily: 'caveat',
                          fontSize: '1.9rem',
                          fontWeight: 'bold',
                        }}
                        style={flexCenter}
                      >
                        440 € / mois
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant='subtitle2'
                        style={flexCenter}
                        sx={{
                          color: theme.palette.text.secondary,
                          textAlign: 'center',
                          fontSize: '0.8rem',
                        }}
                      >
                        <i>montant moyen devis 220 €</i>
                      </Typography>
                    </Box>
                  </Grid>
                  {/* Croissance CA */}
                  <Grid item xs={4} padding={2}>
                    <Typography
                      variant='h6'
                      style={flexCenter}
                      sx={{ color: theme.palette.text.secondary, fontSize: '1.2rem' }}
                    >
                      <b>RDV</b>
                    </Typography>
                    <Box
                      sx={{ border: `3px solid ${theme.palette.primary.main}`, m: 1, py: 1 }}
                      p={2}
                      boxShadow={2}
                      borderRadius={2}
                    >
                      <Typography
                        variant='h6'
                        sx={{
                          color: theme.palette.text.secondary,
                          mb: 0.5,
                          borderBottom: `1px dashed ${darken(theme.palette.primary.main, 0.3)}`,
                          mx: 3,
                          fontSize: '1.6rem',
                        }}
                        style={flexCenter}
                      >
                        <b>1</b>
                      </Typography>
                      <Typography
                        variant='body2'
                        sx={{
                          color: darken(theme.palette.primary.main, 0.1),
                          mb: 0.5,
                          fontFamily: 'caveat',
                          fontSize: '1.9rem',
                          fontWeight: 'bold',
                        }}
                        style={flexCenter}
                      >
                        220 € / mois
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant='subtitle2'
                        style={flexCenter}
                        sx={{
                          color: theme.palette.text.secondary,
                          textAlign: 'center',
                          fontSize: '0.8rem',
                        }}
                      >
                        <i>montant moyen rdv 220 €</i>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box style={flexCenter} sx={{ mt: 3, mb: 1 }}>
                  <Alert sx={{ borderRadius: 20, fontSize: '1.0rem' }} icon={<span />} className='styled-alert'>
                    Soit, fourchette basse, une <b>croissance de CA</b> estimée à <b>960 €</b> grâce à Movalib
                  </Alert>
                </Box>
              </CustomTabPanel>

              {/* Offre de lancement  */}
              {(subscriptionPayment === SubscriptionPaymentInterval.YEAR ||
                subscriptionPayment === SubscriptionPaymentInterval.TWO_YEARS) && (
                <CustomTabPanel value={tabValue} index={SubscriptionTabs.LAUNCH_OFFER}>
                  <Grid container>
                    <Box
                      boxShadow={2}
                      borderRadius={2}
                      style={flexCenter}
                      sx={{
                        border: `2px solid  ${darken(theme.palette.primary.main, 0.2)}`,
                        px: 2.5,
                        py: 1.5,
                      }}
                    >
                      <Grid item xs={12} style={flexCenter}>
                        <Typography
                          variant='body2'
                          sx={{ color: theme.palette.text.secondary, mb: 0.5, fontSize: '1.1rem' }}
                          style={flexStart}
                        >
                          <span
                            style={{
                              color: darken(theme.palette.primary.main, 0.4),
                              fontSize: '1.4rem',
                              marginRight: 15,
                            }}
                          >
                            <b>🎁</b>
                          </span>
                          <b>1 mois</b>&nbsp;offert
                        </Typography>
                      </Grid>
                    </Box>
                    <Grid item xs={8} sx={{ pl: 3, pt: 2 }}>
                      <Typography
                        variant='body2'
                        component='h3'
                        sx={{
                          color: theme.palette.text.secondary,
                          mb: 1,
                          fontSize: '1rem',
                          textAlign: 'center',
                        }}
                      >
                        Abonnement effectif <b>30 jours</b> après la date de mise en service 🏁
                      </Typography>
                    </Grid>

                    <Box
                      boxShadow={2}
                      borderRadius={2}
                      style={flexCenter}
                      sx={{
                        border: `2px solid  ${darken(theme.palette.primary.main, 0.2)}`,
                        px: 2,
                        py: 1,
                        mt: 2,
                      }}
                    >
                      <Grid item xs={12} style={flexCenter}>
                        <Typography
                          variant='body2'
                          component='h3'
                          sx={{ color: theme.palette.text.secondary, mb: 0.5, fontSize: '1rem' }}
                          style={flexStart}
                        >
                          <span
                            style={{
                              color: darken(theme.palette.primary.main, 0.4),
                              fontSize: '1.4rem',
                              marginRight: 15,
                            }}
                          >
                            <b>🎁</b>
                          </span>
                          <center>
                            + 2 formations
                            <br />
                            <s className='striked-price'>
                              <b>258 €</b>
                            </s>
                          </center>
                        </Typography>
                      </Grid>
                    </Box>
                    <Grid item xs={8} sx={{ mt: 3, pl: 3, pt: 1 }}>
                      <Typography
                        variant='body2'
                        component='h3'
                        sx={{
                          color: theme.palette.text.secondary,
                          mb: 1,
                          fontSize: '1rem',
                          textAlign: 'center',
                        }}
                      >
                        Deux sessions de formation offertes d'une valeur de <b>129 €</b> chacune (~ 1 heure par session)
                      </Typography>
                    </Grid>
                  </Grid>
                </CustomTabPanel>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PricingTable;
