// slices/userSlice.ts
import { User } from '@movalib/movalib-commons';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
    user: User | null;
    isAuthenticated: boolean;
}

const initialState: UserState = {
    user: null,
    isAuthenticated: false
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<User | null>) => { state.user = action.payload; },
    setAuthenticatedState: (state, action: PayloadAction<boolean>) => { state.isAuthenticated = action.payload }
  },
});

export const { setUserData, setAuthenticatedState } = userSlice.actions;

export default userSlice.reducer;
