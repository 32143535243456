import { APIMethod, APIResponse, API_BASE_URL, MovaAppType, Subscription, request } from "@movalib/movalib-commons";
import StripeClientSecret from "../models/StripeClientSecret";

export default class SubscriptionService {

    static getSubscription(token: string, subscriptionId: string): Promise<APIResponse<Subscription>> {

        return request({
            url: `${API_BASE_URL}/subscriptions/${subscriptionId}?token=${token}`,
            method: APIMethod.GET,
        });
    }
    
    static finalizeGarageSubscription(token: string, subscriptionId: string): Promise<APIResponse<StripeClientSecret>> {

        return request({
            url: `${API_BASE_URL}/subscriptions/${subscriptionId}/finalize?token=${token}`,
            method: APIMethod.PATCH,
        });
    }
    
    static getStripeSubscriptionSecret(token: string, subscriptionId: string): Promise<APIResponse<StripeClientSecret>> {

        return request({
            url: `${API_BASE_URL}/subscriptions/${subscriptionId}/stripe-secret?token=${token}`,
            method: APIMethod.GET,
        });
    }

    static getStripePublishableKey(token: string, subscriptionId: string): Promise<APIResponse<string>> {
      return request({
        url: `${API_BASE_URL}/subscriptions/${subscriptionId}/stripe-publishable-key?token=${token}`,
        method: APIMethod.GET,
        appType: MovaAppType.GARAGE
      });
    }
}
