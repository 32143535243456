import {
  AccountValidation,
  type Garage,
  GarageService,
  Logger,
  MovaAppType,
  RoleType,
  User,
} from '@movalib/movalib-commons';
import { Alert, Box, Button, Snackbar, type SnackbarCloseReason, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { fr } from 'date-fns/locale';
import { type FunctionComponent, useEffect, useRef, useState } from 'react';
import { ErrorBoundary, type ErrorBoundaryProps } from 'react-error-boundary';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Route, HashRouter as Router, Switch, useHistory, useLocation } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import PrivateRoute from './PrivateRoute';
import MovaSnackbar from './components/MovaSnackbar';
import MyAppBar from './components/MyAppBar';
import ActivateSubscriptionDialog from './dialogs/ActivateSubscriptionDialog';
import { COOKIE_PRO_TOKEN, PALETTE_THIRD_COLOR_MAIN } from './helpers/Constants';
import { PrivateRoutesEnum } from './helpers/Enums';
import { isDemoEnv, readCookie, updateServiceWorker } from './helpers/Tools';
import Agenda from './pages/Agenda';
import Customers from './pages/Customers';
import Employees from './pages/Employees';
import Login from './pages/Login';
import PageNotFound from './pages/PageNotFound';
import Settings from './pages/Settings';
import { SomethingHappened } from './pages/SomethingHappened';
import Pricing from './pages/registration/Pricing';
import Registrations from './pages/registration/Registrations';
import SignUp from './pages/registration/SignUp';
import { setGarageData } from './slices/garageSlice';
import { setSnackbar } from './slices/snackbarSlice';
import { type RootState, persistor, store } from './store';
import theme from './theme';
// FC : Function Component (type du composant App), recommandé par React car plus performant
const App: FunctionComponent = () => {
  // Initialize Tanstack QueryClient
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // Do not refetch data when the window regains focus
      },
    },
  });

  const onThrowUnhandledError: ErrorBoundaryProps['onError'] = (error, info) => {
    Logger.error('Unhandled error:', error, info);
  };

  const onResetApp: ErrorBoundaryProps['onReset'] = (details) => {
    Logger.info('Resetting app', details);

    localStorage.clear();
  };

  return (
    <ErrorBoundary onReset={onResetApp} onError={onThrowUnhandledError} FallbackComponent={SomethingHappened}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
              <ThemeProvider theme={theme}>
                <Router>
                  <AppContent />
                  <MovaSnackbar />
                </Router>
              </ThemeProvider>
            </LocalizationProvider>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

const AppContent: FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const garageFromState = useSelector((state: RootState) => state.garage.garage); // Récupération du garage depuis le state Redux

  const [currentGarage, setCurrentGarage] = useState<Garage | null>(null);
  const [currentRoute, setCurrentRoute] = useState<string>('');
  // const [connectedUser, setConnectedUser] = useState<User>();
  const connectedUser = useRef<User | null>();
  connectedUser.current = useSelector((state: RootState) => state.user.user); // Récupération du user depuis le state Redux

  const isAuthenticated: boolean = Boolean(readCookie(COOKIE_PRO_TOKEN));
  const [updateAvailable, setUpdateAvailable] = useState(false);

  // Permet de forcer le contrôle de mise à jour du service worker à chaque click
  useEffect(() => {
    // Fonction pour gérer l'événement et appeler updateServiceWorker
    const handleUpdateCheck = () => {
      updateServiceWorker();
    };

    // Ajouter l'écouteur d'événements au document
    document.addEventListener('click', handleUpdateCheck);

    // Ne pas oublier de retirer l'écouteur d'événements pour nettoyer
    return () => {
      document.removeEventListener('click', handleUpdateCheck);
    };
  }, []);

  // Permet de détecter une mise à jour de l'app, grace au service worker
  useEffect(() => {
    const handleUpdateFound = () => {
      setUpdateAvailable(true);
    };

    document.addEventListener('swUpdateFound', handleUpdateFound);

    return () => {
      document.removeEventListener('swUpdateFound', handleUpdateFound);
    };
  }, []);

  useEffect(() => {
    if (connectedUser.current) {
      // Gestion profil Admin Garage
      if (User.isGarageAdmin(connectedUser.current) && isAuthenticated) {
        if (garageFromState) {
          setCurrentGarage(garageFromState);
        } else {
          //purgeGlobalSate();
          // Chargement du garage de gestion depuis l'API
          GarageService.getAdministratedGarages().then((response) => {
            if (response.success) {
              setCurrentGarage(response.data ? response.data[0] : null);
              dispatch(setGarageData(response.data ? response.data[0] : null));
            } else {
              dispatch(
                setSnackbar({
                  open: true,
                  message: response.error ?? 'Chargement des données garage impossible',
                  severity: 'error',
                }),
              );
            }
          });
        }
      }
    }

    // Mettre à jour la route actuelle
    setCurrentRoute(location.pathname);

    return () => {
      /* EQ : componentDidUnmount() */
      // Effectuer des opérations de nettoyage ici, telles que la suppression des abonnements, l'annulation des requêtes, etc.
    };
  }, [location.pathname, connectedUser]); // Le tableau de dépendances est vide ici, ce qui signifie que l'effet sera exécuté une seule fois après le premier rendu

  const isPrivateRoute = () => {
    // Vérifier si la page actuelle est parmi les pages exclues
    return Object.values(PrivateRoutesEnum).find((value) => value === location.pathname);
  };

  const handleAccountValidation = (success: boolean, message: string) => {
    // Notification utilisateur et redirection vers le login
    if (success) {
      dispatch(setSnackbar({ open: true, message: message, severity: 'success' }));
    } else {
      dispatch(setSnackbar({ open: true, message: message, severity: 'error' }));
    }

    history.push('/login');
  };

  const isAccessGranted = (role: RoleType) => {
    if (connectedUser.current) {
      switch (role) {
        case RoleType.GARAGE_ADMIN:
          return connectedUser && User.isGarageAdmin(connectedUser.current) && isAuthenticated;
        case RoleType.SALES:
          return connectedUser && User.isSales(connectedUser.current) && isAuthenticated;
      }
    }
    return false;
  };

  const handleCloseAlert = (event: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUpdateAvailable(false);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <>
      {/** Bandeau de DEMO le cas échéant */}
      {isDemoEnv() && (
        <Box
          sx={{
            backgroundColor: theme.palette.secondary.main,
            position: isPrivateRoute() && location.pathname !== '/settings' ? 'relative' : 'fixed',
            width: '100%',
            textAlign: 'center',
            zIndex: 2000,
            marginTop: location.pathname === '/settings' ? 1 : 0,
          }}
        >
          <Typography variant='body2'>
            <i>Environnement de démonstration ...</i>
          </Typography>
        </Box>
      )}

      <Box>
        {/* Barre de navigation globale Movalib Pro */}
        {isPrivateRoute() && Boolean(readCookie(COOKIE_PRO_TOKEN)) && isAccessGranted(RoleType.GARAGE_ADMIN) && (
          <MyAppBar
            garageId={currentGarage ? currentGarage.id : ''}
            activeMenuItem={currentRoute.replace('/', '')}
            sx={{ marginTop: isDemoEnv() ? 2 : 0 }}
          />
        )}
        {/* Le système de gestion des routes de l'application */}
        <Switch>
          <Route
            exact
            path='/activate-subscription'
            component={() => <ActivateSubscriptionDialog closable={false} open={true} />}
          />

          <Route
            exact
            path='/forgot-password'
            component={() => (
              <AccountValidation
                resetPassword={true}
                smsValidation={false}
                movaAppType={MovaAppType.GARAGE}
                onSubmit={handleAccountValidation}
              />
            )}
          />

          <Route
            exact
            path='/validate-account'
            component={() => (
              <AccountValidation
                resetPassword={false}
                smsValidation={false}
                movaAppType={MovaAppType.GARAGE}
                onSubmit={handleAccountValidation}
              />
            )}
          />

          <PrivateRoute
            exact
            path='/settings'
            isAccessGranted={isAccessGranted(RoleType.GARAGE_ADMIN)}
            component={() => <Settings garageId={currentGarage ? currentGarage.id : ''} />}
          />

          <PrivateRoute
            exact
            path={['/', '/agenda']}
            isAccessGranted={isAccessGranted(RoleType.GARAGE_ADMIN)}
            component={() => <Agenda garageId={currentGarage ? currentGarage.id : ''} />}
          />

          <PrivateRoute
            exact
            path='/customers'
            isAccessGranted={isAccessGranted(RoleType.GARAGE_ADMIN)}
            component={() => <Customers garageId={currentGarage?.id} />}
          />

          <PrivateRoute
            exact
            path='/team'
            isAccessGranted={isAccessGranted(RoleType.GARAGE_ADMIN)}
            component={() => <Employees isAdmin />}
          />

          <PrivateRoute
            exact
            path='/registrations'
            isAccessGranted={isAccessGranted(RoleType.SALES)}
            component={Registrations}
          />

          <PrivateRoute exact path='/pricing' isAccessGranted={isAccessGranted(RoleType.SALES)} component={Pricing} />

          <PrivateRoute exact path='/signup' isAccessGranted={isAccessGranted(RoleType.SALES)} component={SignUp} />

          <Route exact path='/login' component={Login} />

          {/* Toujours placer cette route à la fin de la liste pour gérer les 404 */}
          <Route component={PageNotFound} />
        </Switch>
      </Box>

      <div>
        <Snackbar open={updateAvailable} onClose={handleCloseAlert}>
          <Alert
            severity='success'
            variant='filled'
            sx={{ width: '100%', backgroundColor: PALETTE_THIRD_COLOR_MAIN }}
            action={
              <>
                <Button color='inherit' size='small' onClick={handleRefresh}>
                  <b>Actualiser</b>
                </Button>
                {/* <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleCloseAlert}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton> */}
              </>
            }
          >
            Une mise à jour est disponible !
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default App;
