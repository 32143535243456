// MyCustomEvent.tsx
import React, { CSSProperties, FunctionComponent, useState } from 'react';
import { Views } from 'react-big-calendar';
import { DateFormatTypes, Event, EventState, EventType, formatDateByCountryCode } from '@movalib/movalib-commons';
import { differenceInMinutes, format } from 'date-fns';
import { ViewType } from './MyCalendarTypes';
import FireIcon from '@mui/icons-material/LocalFireDepartmentRounded';
import WarningIcon from '@mui/icons-material/WarningRounded';
import QuestionMarkIcon from '@mui/icons-material/HelpRounded';
import { flexCenterCol, flexStart, getEventStateBorder, getEventStateColor, isWithinLastIndicatedHours } from '../../helpers/Tools';
import { Typography, useTheme } from '@mui/material';
import { FIRE_EVENT_DEFAULT_HOURS } from '../../helpers/Constants';
import { flexCenter, formatFrenchVehiclePlate } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import VehicleIcon from '@mui/icons-material/DirectionsCarFilledRounded';

require('./MyCalendar.css');

const DEFAULT_ALERT_ICON_COLOR = 'white';
const DEFAULT_FIRE_ICON_COLOR = 'red';

interface MyCalendarEventProps {
  event: Event;
  view: ViewType;
}

const MyCalendarEventMemo: FunctionComponent<MyCalendarEventProps> = ({ event, view }) => {

  const theme = useTheme();
  const [alertIconColor, setAlertIconColor] = useState<string>(DEFAULT_ALERT_ICON_COLOR);
  const [fireIconColor, setFireIconColor] = useState<string>(DEFAULT_FIRE_ICON_COLOR);

  const now = new Date();
  let isPastEvent:boolean = false;
  let isMinDuration:boolean = false;
  let isFireEvent:boolean = false;

  if(event.end)
    isPastEvent = event.end < now;

  if(event.start && event.end){
    // Est-ce que la durée de l'event est égale à l'interval mini ?
    isMinDuration = differenceInMinutes(event.end, event.start) <= 30;
  }

  if(event.start)
    isFireEvent = isWithinLastIndicatedHours(event.start, FIRE_EVENT_DEFAULT_HOURS);

  const eventStyle: CSSProperties = {
    backgroundColor: getEventStateColor(event, theme),
    fontFamily: 'Outfit',
    opacity: isPastEvent ? 0.5  : 1,
    fontSize: 12,
    padding: '4px',
    paddingLeft: '8px',
    paddingRight: '8px',
    borderRadius: '8px',
    border: getEventStateBorder(event)
  };

  const blinkStyle: CSSProperties = {
    animation: 'blink 2s linear infinite',
  };

  const diagonalStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: 'repeating-linear-gradient(45deg, transparent, transparent 22px, #fff 22px, #fff 25px)',
    opacity: event.state === EventState.DONE ? 1 : 0, // n'affiche la superposition que si l'événement est clôturé
  };

  const quoteRejectedStyle: CSSProperties = {
    position: 'relative',
    backgroundColor: 'white',
    color: 'black', 
    padding: '2px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    fontSize: '0.7rem',
    top: '75%',
    left: '50%',
    border: `1px solid ${getEventStateColor(event, theme)}`,
    transform: 'translate(-50%, -40%)',
    //animation: event.state === EventState.REJECTED ? 'blink 3s linear infinite' :  '',
    opacity: event.state === EventState.REJECTED ? 1 : 0, // n'affiche la superposition que si l'événement est rejeté (cela signifie que le devis est refusé
  }

  const displayAlertIcon = () => {
    return event.state === EventState.REJECTED; 
  }

  return (
    <div style={eventStyle}>
      {event.state === EventState.REJECTED && <div style={quoteRejectedStyle}><b>DEVIS REFUSÉ</b></div>}
      <div>
        <Typography sx={{ p:0, fontSize: '0.8rem'}} style={flexStart}>
         {/*  <b style={flexCenter}>
            {displayAlertIcon() && <WarningIcon sx={{ color: alertIconColor, fontSize: '1rem', mr: '2px' }} style={blinkStyle} /> }
          </b> */}
          <b style={flexCenter}>
            <QuestionMarkIcon sx={{ color: isFireEvent ? 'red' : '#00BF08', fontSize: '1rem', pr:0.4, display: event.state === EventState.NEW ? 'inherit' : 'none' }} />
            {formatDateByCountryCode(event.start, 'FR', DateFormatTypes.LONG_FORMAT_DATETIME_LITERAL)}
          </b>
        </Typography> 
      </div>
      <div style={{marginBottom: 3,  alignItems: 'center'}} className='my-calendar-event-label'>

    
        {/*

          <FireIcon sx={{ color: fireIconColor, fontSize: '1rem', display: isFireEvent ? 'inherit' : 'none' }} />


          //TODO : prévoir un boolean "alert" sur l'évènement pour savoir quand afficher la flamme (avec une tooltip reprenant le message d'alerte de l'évènement ?)

        */}   

        <div style={{ 
          backgroundColor: alertIconColor, 
          height: '1em', 
          width: '1em', 
          marginRight: '0.5em' ,
          display:'none'  //TODO : activer si usage avéré
        }} />
        {(event.type !== EventType.UNAVAILABILITY) && <div><strong>
           <VehicleIcon style={{ fontSize: 16, position: 'relative', top: '2px' }} /> {formatFrenchVehiclePlate(event.vehicle?.plate)} (&nbsp;{event.customer?.lastname}&nbsp;)</strong><br/></div> }
        {event.title}<br/>
      </div>
{/*       <Typography sx={{ fontSize: 11}}>{event.vehicle?.brand} {event.vehicle?.model} {event.vehicle?.version}</Typography> */}
    </div>
  );
}

export default MyCalendarEventMemo;