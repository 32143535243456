import { type Customer, MovaVehicleTireField, VehiclePlateField } from '@movalib/movalib-commons';
import { formatVehicleTire } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import { useFormik } from 'formik';
import invariant from 'invariant';
import { noop } from 'lodash';
import { Fragment, memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useBoolState } from '../../helpers/hooks/useBoolState';
import { useFetchAdministratedGarages, useGarageAddCustomerVehicle } from '../../query/garage/GarageQuery';
import { useGetVehicleDetails } from '../../query/vehicle/VehicleQuery';
import { setSnackbar } from '../../slices/snackbarSlice';
import theme from '../../theme';

const addVehicleSchema = Yup.object({
  plate: Yup.string().min(1).required(),
  currentMileage: Yup.number().default(0).min(0),
  averageMileagePerYear: Yup.number().default(0).min(0),
  tireInfos: Yup.object({
    width: Yup.string().length(3).required(),
    height: Yup.string().length(2).required(),
    diameter: Yup.string().length(3).required(),
    speedIndex: Yup.string().length(3).required(),
  }),
});

type AddVehicleForm = Yup.InferType<typeof addVehicleSchema>;

type AddVehicleProps = {
  customer: Customer;
};

export const AddVehicle = memo(({ customer }: AddVehicleProps) => {
  const dispatch = useDispatch();

  const [requestedPlate, setRequestedPlate] = useState<string>('');
  const [foundVehicle, setFoundVehicle] = useState<string>('');
  const [vehicleError, setVehicleError] = useState<string | undefined>(undefined);

  const { isAddVehicleModalOpen, toggleAddVehicleModalOpen } = useBoolState(false, 'addVehicleModalOpen');

  const { values, handleChange, setFieldValue, resetForm } = useFormik<AddVehicleForm>({
    initialValues: {
      plate: '',
      currentMileage: 0,
      averageMileagePerYear: 0,
      tireInfos: {
        width: '',
        height: '',
        diameter: '',
        speedIndex: '',
      },
    },
    validationSchema: addVehicleSchema,
    onSubmit: noop,
    enableReinitialize: true,
  });

  const { data: garage } = useFetchAdministratedGarages();

  const { mutateAsync: getVehicleDetails, isPending } = useGetVehicleDetails();
  const { mutateAsync: createVehicle } = useGarageAddCustomerVehicle();

  const onOpenProxy = useCallback(() => {
    resetForm();
    setRequestedPlate('');
    setFoundVehicle('');
    setVehicleError(undefined);
    toggleAddVehicleModalOpen();
  }, [toggleAddVehicleModalOpen, resetForm]);

  const onChangeTireInfosProxy = useCallback(
    (tireInfos: AddVehicleForm['tireInfos']) => {
      setFieldValue('tireInfos', tireInfos);
    },
    [setFieldValue],
  );

  const onValidPlateProxy = useCallback(
    async (plate: string) => {
      // While the plate is the same as before, do nothing (infinite loop)
      if (requestedPlate === plate) {
        return;
      }

      setRequestedPlate(plate);
      setFoundVehicle('');

      const response = await getVehicleDetails(plate);
      if (response.success && response.data) {
        await setFieldValue('plate', plate);
        setVehicleError(undefined);
        setFoundVehicle(response.data);
      } else {
        if (!String(response.error).includes('404')) {
          setVehicleError(response.error);
          await setFieldValue('plate', '');
        } else {
          setVehicleError('Identification du véhicule impossible');
          await setFieldValue('plate', '');
        }
      }
    },
    [setFieldValue, getVehicleDetails, requestedPlate],
  );

  const onSubmitForm = useCallback(async () => {
    invariant(garage?.id, 'Garage ID is not defined');
    const { plate, averageMileagePerYear, currentMileage, tireInfos } = values;
    const formattedTireInfo = formatVehicleTire(tireInfos).replace(/[^0-9A-QS-Za-qs-z]/g, '');

    if (
      (formattedTireInfo.length !== 0 && formattedTireInfo.length < 10) ||
      (formattedTireInfo.length === 10 && !new RegExp(/^\d{9}[A-Za-z]$/).test(formattedTireInfo))
    ) {
      setVehicleError('Informations de pneus invalides');
      return;
    }
    setVehicleError(undefined);

    // Send to API
    const response = await createVehicle({
      garageId: garage.id ?? '',
      customerId: customer.id,
      plate,
      currentMileage,
      averageMileagePerYear,
      tireWidth: tireInfos.width || undefined,
      tireHeight: tireInfos.height || undefined,
      tireDiameter: tireInfos.diameter || undefined,
      tireSpeedIndex: tireInfos.speedIndex || undefined,
    });

    if (response.success) {
      dispatch(
        setSnackbar({
          open: true,
          message: response.data ?? 'Véhicule ajouté au fichier client !',
          severity: 'success',
        }),
      );
      toggleAddVehicleModalOpen();
    } else {
      dispatch(
        setSnackbar({ open: true, message: response.error ?? "Erreur lors de l'ajout du véhicule", severity: 'error' }),
      );
    }
  }, [createVehicle, customer.id, toggleAddVehicleModalOpen, garage?.id, values, dispatch]);

  return (
    <>
      <Button variant='contained' size='small' color='primary' sx={{ alignSelf: 'start', mt: 2 }} onClick={onOpenProxy}>
        Ajouter un véhicule
      </Button>
      <Dialog open={isAddVehicleModalOpen} maxWidth='sm' scroll='body' onClose={toggleAddVehicleModalOpen} fullWidth>
        <Toolbar
          disableGutters
          variant='dense'
          sx={{
            minHeight: 3,
            backgroundColor: theme.palette.grey[200],
            py: 0,
          }}
        >
          <DialogTitle sx={{ flexGrow: 1 }} component={'div'}>
            <Typography
              py={0}
              my={0}
              sx={{
                pl: '34px',
                color: theme.palette.text.primary,
                textAlign: 'center',
              }}
            >
              <span>
                AJOUTER <b>UN VÉHICULE</b>
              </span>
            </Typography>
          </DialogTitle>
          <Tooltip title='Fermer'>
            <IconButton sx={{ mr: 1 }} size='small' aria-label='close' onClick={toggleAddVehicleModalOpen}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <VehiclePlateField onValidVehiclePlate={onValidPlateProxy} />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={6}>
              <TextField
                type='number'
                fullWidth
                margin='normal'
                id='currentMileage'
                label='Kilométrage actuel'
                name='currentMileage'
                value={values.currentMileage}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin='normal'>
                <InputLabel id='averageMileagePerYear-label'>Kilométrage moyen annuel</InputLabel>
                <Select
                  labelId='averageMileagePerYear-label'
                  id='averageMileagePerYear'
                  name='averageMileagePerYear'
                  value={values.averageMileagePerYear}
                  onChange={handleChange}
                  label='Kilométrage moyen annuel'
                >
                  <MenuItem value={5000}>5 000</MenuItem>
                  <MenuItem value={10000}>10 000</MenuItem>
                  <MenuItem value={15000}>15 000</MenuItem>
                  <MenuItem value={20000}>20 000</MenuItem>
                  <MenuItem value={25000}>25 000</MenuItem>
                  <MenuItem value={30000}>30 000</MenuItem>
                  <MenuItem value={50000}>50 000</MenuItem>
                  <MenuItem value={75000}>75 000</MenuItem>
                  <MenuItem value={100000}>100 000</MenuItem>
                  <MenuItem value={999999}>+100 000</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <MovaVehicleTireField onChangeVehicleTire={onChangeTireInfosProxy} />
            </Grid>
          </Grid>
          {!!vehicleError && (
            <Alert severity='error' sx={{ mb: 2 }}>
              {vehicleError}
            </Alert>
          )}
          <DialogContentText id='add-vehicle-dialog-description' align='center' sx={{ paddingTop: 2 }}>
            {foundVehicle && (
              <Fragment>
                <Typography variant='h6' color={theme.palette.primary.main} sx={{ fontSize: '16px' }}>
                  <b>{values.plate}</b>, véhicule trouvé
                </Typography>
                <p />
                <Typography variant='body2' color={theme.palette.text.primary}>
                  <b>{foundVehicle}</b>
                </Typography>
              </Fragment>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button variant='contained' color='inherit' onClick={toggleAddVehicleModalOpen}>
            Annuler
          </Button>
          <LoadingButton
            onClick={onSubmitForm}
            disabled={!foundVehicle}
            variant='contained'
            color='primary'
            loading={isPending}
          >
            Enregistrer
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
});
