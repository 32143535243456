import Customer from "../models/Customer";
import { API_BASE_URL } from "../helpers/Constants";
import { APIMethod } from "../helpers/Enums";
import { APIResponse, request } from "@movalib/movalib-commons/dist/src/helpers/ApiHelper";
import { MovaAppType } from "@movalib/movalib-commons";

export default class CustomerService {

    static updateCustomer(garageId: string, req: any): Promise<APIResponse<string>>  {
        return request({
            url: `${API_BASE_URL}/garage/${garageId}/customers`,
            method: APIMethod.PATCH,
            body: JSON.stringify(req),
            appType: MovaAppType.GARAGE
          });
    }

    static countCustomers(garageId: string): Promise<APIResponse<number>>  {
        return request({
            url: `${API_BASE_URL}/garage/${garageId}/customers/count`,
            method: APIMethod.GET,
            appType: MovaAppType.GARAGE
        });
    }

    static searchCustomers(garageId: string, query: string): Promise<APIResponse<Customer[]>>  {
        return request({
            url: `${API_BASE_URL}/garage/${garageId}/customers/search?query=${query}`,
            method: APIMethod.GET,
            appType: MovaAppType.GARAGE
        });
    }

    static createCustomer(garageId: string, req: any): Promise<APIResponse<string>>  {
        return request({
            url: `${API_BASE_URL}/garage/${garageId}/customers`,
            method: APIMethod.POST,
            body: JSON.stringify(req),
            appType: MovaAppType.GARAGE
          });
    }

    /**
     * Il s'agira ici de récupérer les clients d'un garage donné
     * @returns
     * @param garageId
     * @param size
     * @param page
     */
    static getCustomers(garageId: string, size: number, page: number): Promise<APIResponse<Customer[]>>  {
        return request({
            url: `${API_BASE_URL}/garage/${garageId}/customers?size=${size}&page=${page}`,
            method: APIMethod.GET,
            appType: MovaAppType.GARAGE
        });
    }

    /**
     * Supprimer un client d'un garage
     * /!\ Ne supprime pas l'utilisateur, mais supprime le fichier client du garage /!\
     * @param garageId
     * @param customerId
     */
    static deleteCustomer(garageId: string, customerId: string): Promise<APIResponse<string>>  {
        return request({
            url: `${API_BASE_URL}/garage/${garageId}/customers/${customerId}`,
            method: APIMethod.DELETE,
            appType: MovaAppType.GARAGE,
        });
    }

}
