import type { User } from '@movalib/movalib-commons';
import AddIcon from '@mui/icons-material/Add';
import DemoIcon from '@mui/icons-material/Computer';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import PendingIcon from '@mui/icons-material/HourglassEmpty';
import MenuIcon from '@mui/icons-material/MenuRounded';
import AppIcon from '@mui/icons-material/PhoneAndroidRounded';
import PricingIcon from '@mui/icons-material/EuroSymbol';
import {
  AppBar,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  type Theme,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import type React from 'react';
import { useState } from 'react';
import HeaderLogo from '../../assets/images/logo/header_logo.png';
import { flexEnd, flexStart } from '../../helpers/Tools';

// Définition du type pour les props du composant
interface SignUpHeaderProps {
  title?: string;
  connectedUser: User;
  onPageClick: (page: string) => void;
}

const SignUpHeader: React.FC<SignUpHeaderProps> = ({ connectedUser, title, onPageClick }) => {
  const theme: Theme = useTheme();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const proDemoUrl = 'https://pro-demo.movalib.com';
  const appDemoUrl = 'https://app-demo.movalib.com';

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElNav(null);
  };

  const handlePageClick = (page: string) => {
    handleCloseMenu();
    // Appel du callback
    onPageClick(page);
  };

  return (
    <div style={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 5000 }}>
      <AppBar position='static' elevation={4}>
        <Toolbar
          variant='dense'
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            color: '#3C4043',
            backgroundColor: theme.palette.primary.light,
            borderColor: 'transparent',
            p: 0,
            m: 0,
          }}
        >
          <Grid container sx={{ display: 'flex' }}>
            <Grid item xs={10} style={flexStart}>
              <IconButton
                edge='start'
                color='inherit'
                aria-label='logo'
                onClick={() => handlePageClick('REGISTRATIONS')}
                disableRipple
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  ':hover': {
                    bgcolor: 'transparent',
                  },
                }}
              >
                <img src={HeaderLogo} alt='Logo Movalib' style={{ width: '70%' }} />
              </IconButton>
              {title && (
                <Typography variant='h6' color={theme.palette.text.secondary}>
                  <b>{title}</b>
                </Typography>
              )}
            </Grid>
            <Grid item xs={2} style={flexEnd}>
              <Button
                variant='text'
                startIcon={<MenuIcon />}
                color='inherit'
                onClick={handleOpenMenu}
                sx={{
                  pr: 2,
                  mr: 1,
                  textTransform: 'none',
                  backgroundColor: 'transparent',
                }}
              >
                <Typography variant='h6' sx={{ fontSize: 18 }}>
                  <b>Menu</b>
                </Typography>
                {/* {connectedUser?.firstname}&nbsp;{connectedUser?.lastname} */}
              </Button>
            </Grid>
          </Grid>
          <Menu
            id='menu-appbar'
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseMenu}
            sx={{
              zIndex: 5000,
              display: { xs: 'block' },
            }}
          >
            <MenuItem key={0} onClick={() => handlePageClick('PRICING')}>
              <PricingIcon sx={{ mr: 2 }} />
              <Typography textAlign='center'>Tarification / Abonnements</Typography>
            </MenuItem>
            <MenuItem key={1} component='a' href={proDemoUrl} target='_blank' rel='noopener noreferrer'>
              <DemoIcon sx={{ mr: 2 }} />
              <Typography textAlign='center'>Movalib Pro - Démo</Typography>
            </MenuItem>
            <MenuItem key={2} component='a' href={appDemoUrl} target='_blank' rel='noopener noreferrer'>
              <AppIcon sx={{ mr: 2 }} />
              <Typography textAlign='center'>Movalib (App) - Démo</Typography>
            </MenuItem>
            <MenuItem key={3} onClick={() => handlePageClick('SIGNUP')}>
              <AddIcon sx={{ mr: 2 }} />
              <Typography textAlign='center'>Nouvelle inscription</Typography>
            </MenuItem>
            <MenuItem key={4} onClick={() => handlePageClick('REGISTRATIONS')}>
              <PendingIcon sx={{ mr: 2 }} />
              <Typography textAlign='center'>Inscription(s) en cours</Typography>
            </MenuItem>
            <MenuItem key={5} onClick={() => handlePageClick('LOGOUT')}>
              <LogoutIcon sx={{ mr: 2 }} />
              <Typography textAlign='center'>Déconnexion</Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default SignUpHeader;
