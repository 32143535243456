class Logger {

    static isEnabled(): boolean {
        return localStorage.getItem('modeDebug') === 'true';
    }

    static enableLogging() {
        localStorage.setItem('modeDebug', 'true');
    }

    static disableLogging() {
        localStorage.removeItem('modeDebug');
    }

    static info(...args: any[]) {
        if (Logger.isEnabled()) {
            console.info('INFO:', ...args);
        }
    }

    static error(...args: any[]) {
        if (Logger.isEnabled()) {
            console.error('ERROR:', ...args);
        }
    }
}
  
export default Logger;  