import { GarageService, Logger, MovaDialog, MovaFormField, validateEmail, validateField } from '@movalib/movalib-commons';
import { Alert, Box, Button, Grid, IconButton, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState, type FC, FormEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../slices/snackbarSlice';
import InfoIcon from '@mui/icons-material/InfoRounded';
import { flexEnd, flexStart } from '../helpers/Tools';
import { Palette } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/AddRounded';
import { flexCenter } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import SetupIcon from '@mui/icons-material/HourglassBottom';
import { PALETTE_THIRD_COLOR_LIGHT, PALETTE_THIRD_COLOR_MAIN } from '../helpers/Constants';

interface AddPrestationDialogProps {
    open: boolean,
    garageId: string,
    showHelp?: boolean,
    onClose?: (refresh:boolean) => void;
}

type PrestationForm = {
    name: MovaFormField,
    downtime: MovaFormField,
    appointmentDelay: MovaFormField,
    operationsList: MovaFormField,
    frequency : MovaFormField
  }
  
  const initialFormState = {
    name: { value: '', error: '', isValid: true },
    downtime: { value: 0, error: '', isValid: true },
    appointmentDelay: { value: 0, error: '', isValid: true },
    operationsList: { value: ['', ''], error: '', isValid: true },
    frequency: { value: '', error: '', isValid: true },
  };

const AddPrestationDialog: FC<AddPrestationDialogProps> = ({ open, garageId, showHelp = false, onClose }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [form, setForm] = useState<PrestationForm>(initialFormState);
    const dispatch = useDispatch();


    const purgeLocalState = () => {
        setForm(initialFormState);
    }

    const handleOnClose = (refresh:boolean) => {
        purgeLocalState();
        if(onClose){
            onClose(refresh);
        }
    }

    const validateForm = () => {

        let newForm: PrestationForm = { ...form };

        // Validator pour les champs obligatoires
        newForm.name = validateField(form.name, value => !!value, 'Champ obligatoire');
        newForm.downtime = validateField(form.downtime, value  => (!!value && value !== ''), 'Champ obligatoire');
        newForm.appointmentDelay = validateField(form.appointmentDelay, value => value == '0' || (!!value && value !== ''), 'Champ obligatoire');
        newForm.frequency = validateField(form.frequency, value => !!value, 'Champ obligatoire');

        setForm(newForm);

        let isValid = Boolean(newForm.name.isValid && newForm.downtime.isValid && newForm.appointmentDelay.isValid
            && newForm.operationsList.isValid && newForm.frequency.isValid);
    
        return isValid;
    }

    const isNotValid = () => {
        return Boolean(form.name.value.length == 0 || form.downtime.value.length == 0 
            || form.appointmentDelay.value.length == 0 || form.frequency.value.length == 0 )
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            if(validateForm() && garageId) {
            
                // Requête de création d'une prestation
                let req = {
                    name: form.name.value,
                    downtime: form.downtime.value,
                    appointmentDelay: form.appointmentDelay.value,
                    operationsList: form.operationsList.value,
                    frequency: form.frequency.value
                };
        
                GarageService.createGaragePrestationRequest(garageId, req)
                .then(response => {
                    if(response.success){
                        dispatch(setSnackbar({ open: true, message: response.data ?? "La demande d'ajout de prestation a été envoyée avec succès", severity: 'success' }));
                        // On ferme la dialog box avec demande de refresh
                        handleOnClose(true);
                    }else{
                        dispatch(setSnackbar({ open: true, message: response.error ?? "Envoi de la demande impossible", severity: 'error' }));
                    }
                });
            }

        }catch (error){
            Logger.error('Error occurred during submission:', error);
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const fieldName: string = e.target.name;
        const fieldValue: string = e.target.value;
        // Vérifie si le champ modifié est une opération
        const operationMatch = fieldName.match(/^operation(\d+)$/);
  
        if (operationMatch) {
            // Extraire l'index, ajuster parce que le tableau commence à l'index 0 et nos noms à 1
            const operationIndex = parseInt(operationMatch[1], 10) - 1;

            // Mettre à jour operationsList.value à l'index spécifié
            setForm(prevForm => ({
                ...prevForm,
                operationsList: {
                    ...prevForm.operationsList,
                    value: prevForm.operationsList.value.map((operation: String, index: number) => 
                        index === operationIndex ? fieldValue : operation
                    ),
                    isValid: true // Assumer valide pour simplifier, ajustez selon la logique de validation
                }
            }));
        } else {
            // Gérer les autres champs ici, si nécessaire
            const newField: MovaFormField = { [fieldName]: { value: fieldValue, isValid: true } };



            setForm({ ...form, ...newField});
        }
    }

    const addOperation = () => () => {
        setForm(prevForm => (
            { ...prevForm, 
                operationsList: {
                    ...prevForm.operationsList,
                    // Créez un nouveau tableau avec les éléments existants plus un élément vide
                    value: [...prevForm.operationsList.value, ''],
                },
            }));   
    };    

    return (
        <>
            <MovaDialog fullScreen={isMobile} open={open} onClose={() => handleOnClose(false)} leafImageColor="green" 
                maxWidth='sm'
                closable
                title={"DEMANDE D'AJOUT D'UNE PRESTATION"} 
                actions={
                    <Button onClick={handleSubmit} 
                        color="primary" sx={{  width:'90%' }} 
                        disabled={isNotValid()} 
                        variant='contained'>
                        Envoyer la demande
                    </Button>
                }
            >
                <Grid container>

                    {showHelp && <Alert severity="success" sx={{ mb: 2 }} icon={<SetupIcon />} style={flexCenter} className='styled-alert'>
                            <Typography  style={flexCenter}>
                                Votre nouvelle prestation sera disponible d'ici quelques jours. 
                                En cas d'urgence, pensez à utiliser la prestation "Autre..."
                            </Typography>
                    </Alert>}

                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            required
                            autoFocus
                            fullWidth
                            id="name"
                            label="Nom de la prestation"
                            name="name"                    
                            onChange={e => handleInputChange(e)}
                            value={form.name.value}
                            error={Boolean(form.name.error)}
                            helperText={Boolean(form.name.error) ? form.name.error : 'Choisissez un nom assez court'}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{mt: 2}} style={flexStart}>
                        <Typography variant="body1">
                            Quelles opérations doivent généralement être réalisées ?
                            <Tooltip title="Exemple : remplacer le filtre à huile">
                                <IconButton size='small' color='primary' 
                                    sx={{color: theme.palette.primary.main, p: 0, ml: 1, mb: '2px' }}><InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Grid>
                    {form.operationsList.value.map((operation:string, operationIndex:number) => (
                        <Grid container key={operationIndex+1}>
                            <Grid item xs={10}>
                                <TextField
                                        margin="normal"
                                        variant='standard'
                                        size='small'
                                        fullWidth
                                        id="operation"
                                        label={`Opération n° ${operationIndex+1}`}
                                        name={`operation${operationIndex+1}`}
                                        onChange={e => handleInputChange(e)}
                                        value={operation}
                                        error={Boolean(form.name.error)}
                                    />
                            </Grid>
                            <Grid item xs={2} style={flexCenter}>
                                {(operationIndex === 0) && 
                                    <Tooltip title='Ajouter une opération' placement='top'>
                                        <IconButton onClick={addOperation()}>
                                            <AddIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Grid>
                        </Grid>
                    ))}
                    <Grid item xs={8} sx={{mt: 2}} style={flexStart}>
                        <Typography variant="body1">
                        Durée courante d'immobilisation véhicule : 
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{mt: 2}}>
                        <Box style={flexEnd}><TextField
                            id="downtime"
                            value={form.downtime.value}
                            type="number" 
                            required
                            size='small'
                            name='downtime'
                            onChange={(e) => {
                                handleInputChange(e)
                            }}
                            error={!form.downtime.isValid}
                            sx={{ width: '66px'}}
                            InputProps={{ inputProps: { min: 0 } }}
                        /> <Typography variant="body2" sx={{ml: 2}}> Heure(s)</Typography> </Box>
                    </Grid>
                    <Grid item xs={9} sx={{mt: 2}} style={flexStart}>
                        <Typography variant="body1">
                        Délai de prévenance avant pris de rendez-vous : 
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{mt: 2}}>
                        <Box style={flexStart}><TextField
                            id="appointmentDelay"
                            value={form.appointmentDelay.value}
                            type="number" 
                            required
                            size='small'
                            name='appointmentDelay'
                            onChange={(e) => {    // Convertir la valeur en entier, en ignorant les entrées non numériques
                                const value = e.target.value;
                                const intValue = value === "" ? "" : parseInt(value, 10);
                                // Utiliser une RegExp pour vérifier si la valeur est un entier valide
                                if (/^\d*$/.test(value)) { // Cette expression régulière accepte des nombres entiers, y compris "0"
                                  // Créez ici un nouvel objet event ou ajustez directement la valeur avant de passer à handleInputChange
                                  e.target.value = intValue.toString();
                                  handleInputChange(e);
                                }
                            }}
                            error={!form.appointmentDelay.isValid}
                            sx={{ width: '100px', mr: 2}}
                            InputProps={{ inputProps: 
                                {   
                                    min: 0
                                } 
                            }}
                        />  <Typography variant="body2" sx={{mr: 1}}> Jour(s)</Typography> </Box>
                    </Grid>
                    <Grid item xs={12} sx={{mt: 3}} style={{ textAlign: 'left' }}>
                        <Typography variant="body1" >
                        Quelle est en moyenne sa fréquence d'entrée en atelier ? 
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="frequency"
                            name="frequency"                  
                            onChange={e => handleInputChange(e)}
                            placeholder='Tous les jours, toutes les semaines, tous les mois, selon la saison etc...'
                            value={form.frequency.value}
                            error={Boolean(form.frequency.error)}
                            helperText={Boolean(form.frequency.error) ? form.frequency.error : 'Cela nous aidera à la classifier'}
                        />
                    </Grid>
                </Grid>

            </MovaDialog>
        </>
    );
}

export default AddPrestationDialog;
