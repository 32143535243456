import React, { FunctionComponent, ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { flexEnd } from '../helpers/Tools';

interface UploadDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClose?: () => void;
  yesLabel?: ReactNode;
  noLabel?: ReactNode;
  title?: string;
  message?: ReactNode;
}

const UploadDialog: FunctionComponent<UploadDialogProps> = ({ open, onClose, onCancel, noLabel = 'NON', onConfirm, yesLabel = 'OUI', title, message }) => {
  const defaultTitle = "Transmission du fichier";
  const defaultMessage = "Êtes-vous sûr de vouloir continuer ?";

  return (
    <Dialog
      fullWidth maxWidth="sm" 
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container>
          <Grid item xs={10}>
            {title || defaultTitle}
          </Grid>
          <Grid item xs={2} style={flexEnd}>
            <Tooltip title="Annuler">
              <IconButton sx={{ mr: 0}} 
                  size="small" 
                  aria-label="close"
                  onClick={onCancel}>
                  <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
 
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message || defaultMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div>
          <input
              accept="image/*, application/pdf"
              style={{ display: 'none' }}
              id="raised-button-file"
              type="file"
              onChange={onConfirm}
          />
          <label htmlFor="raised-button-file">
              <Button component="span" color="primary" variant='contained' sx={{ mr: 2 }}>
                  {yesLabel}
              </Button>
          </label>
        </div>
        {onClose && <Button onClick={onClose} color="inherit" sx={{ ml: 1 }} variant='outlined'>
          {noLabel}
        </Button>}
      </DialogActions>
    </Dialog>
  );
}

export default UploadDialog;