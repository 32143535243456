import { StyleSheet, Text, type TextProps, View, type ViewProps } from '@react-pdf/renderer';
import type { PropsWithChildren } from 'react';

const defaultStyle = StyleSheet.create({
  table: {
    display: 'flex',
    flexDirection: 'column',
    border: '1pt solid #757575',
    borderBottom: '0',
    borderRight: '0',
    fontSize: 10,
  },
  th: {
    display: 'flex',
    flexDirection: 'column',
  },
  tr: { display: 'flex', flexDirection: 'row', flewGrow: 1, borderBottom: '1pt solid #757575', minHeight: '20pt' },
  tc: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 5,
    borderRight: '1pt solid #757575',
  },
});

export const Table = ({ children, style, ...props }: PropsWithChildren<ViewProps>) => (
  <View style={Array.isArray(style) ? [defaultStyle.table, ...style] : [defaultStyle.table, style ?? {}]} {...props}>
    {children}
  </View>
);

export const TableHeader = ({ children, style, ...props }: PropsWithChildren<ViewProps>) => (
  <View style={Array.isArray(style) ? [defaultStyle.th, ...style] : [defaultStyle.th, style ?? {}]} {...props}>
    {children}
  </View>
);

export const TableBody = TableHeader;

export const TableRow = ({ children, style, ...props }: PropsWithChildren<ViewProps>) => (
  <View style={Array.isArray(style) ? [defaultStyle.tr, ...style] : [defaultStyle.tr, style ?? {}]} {...props}>
    {children}
  </View>
);

export const TableCell = ({ children, style, ...props }: PropsWithChildren<TextProps>) => (
  <Text style={Array.isArray(style) ? [defaultStyle.tc, ...style] : [defaultStyle.tc, style ?? {}]} {...props}>
    {children}
  </Text>
);

export const TableCellContainer = ({ children, style, ...props }: PropsWithChildren<ViewProps>) => (
  <View style={Array.isArray(style) ? [defaultStyle.tc, ...style] : [defaultStyle.tc, style ?? {}]} {...props}>
    {children}
  </View>
);
