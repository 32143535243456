import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { store } from "./store";
import Logger from './helpers/Logger';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Activation des logs console si nous ne sommes pas en PRODUCTION sinon contrôle de la variable debugMode
if (process.env.NODE_ENV !== 'production' || localStorage.getItem('debugMode') === 'true') {
  Logger.enableLogging();
} else {
  Logger.disableLogging();
}

// L'ajout du "!" pour indiquer que la variable est forcément non null (car getElementById() renvoie  HTMLElement | null 
//or le createContainer n'accepte pas de variable avec un type nullish)
const root = createRoot(document.getElementById('root')!);

root.render(
/*     <React.StrictMode> */
      <Provider store={store}>
          <App />
      </Provider>
/*     </React.StrictMode> */
);

serviceWorkerRegistration.register();