import type { ErrorBoundaryPropsWithComponent } from 'react-error-boundary';

import { Login } from '@mui/icons-material';
import { Button, Link, Typography, darken } from '@mui/material';
import GreenLeaf from '../assets/images/leaf_green_large.png';
import PinkLeaf from '../assets/images/leaf_pink_large.png';
import LogoMovalib from '../assets/images/logo/logo_pro_large_border.png';
import Towing from '../assets/images/towing_car.svg';
import theme from '../theme';

export const SomethingHappened: ErrorBoundaryPropsWithComponent['FallbackComponent'] = ({
  error,
  resetErrorBoundary,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <img
        src={GreenLeaf}
        style={{
          position: 'fixed',
          float: 'left',
          width: '250px',
          height: '400px',
          top: '-20%',
          left: '0%',
          opacity: '0.3',
          zIndex: -8,
        }}
        alt='Feuille Verte Movalib'
      />
      <img
        src={LogoMovalib}
        alt='Logo Movalib'
        style={{
          position: 'fixed',
          width: '400px',
          top: '100px',
        }}
      />
      <img src={Towing} alt='Towing' style={{ width: '200px' }} />
      <Typography variant='h4'>Oups !</Typography>
      <Typography variant='body1'>Une erreur est survenue. Merci de vous reconnecter</Typography>

      <Link href='/login' underline='none'>
        <Button
          variant='contained'
          size='large'
          onClick={resetErrorBoundary}
          startIcon={<Login />}
          sx={{
            backgroundColor: theme.palette.primary.main,
            '&:hover': { backgroundColor: darken(theme.palette.primary.light, 0.1) },
          }}
        >
          <Typography variant='body1'>Se reconnecter</Typography>
        </Button>
      </Link>
      <img
        src={PinkLeaf}
        style={{
          position: 'fixed',
          float: 'right',
          width: '250px',
          height: '400px',
          bottom: '-20%',
          right: '0%',
          opacity: '0.3',
          zIndex: '-10',
        }}
        alt='Feuille Rose Movalib'
      />
    </div>
  );
};
