import { ConfirmationDialog, formatPhoneNumber } from '@movalib/movalib-commons';
import { Message, Phone } from '@mui/icons-material';
import { Button, Link, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { type FormikConfig, useFormik } from 'formik';
import invariant from 'invariant';
import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useBoolState } from '../helpers/hooks/useBoolState';
import { useFetchAdministratedGarages, useGarageSendSupportRequest } from '../query/garage/GarageQuery';

export const ContactBtnMenu = memo(() => {
  const dispatch = useDispatch();

  const [anchorElContactBtn, setAnchorElContactBtn] = React.useState<null | HTMLElement>(null);
  const { isConfirmDialogOpened, toggleConfirmDialogOpened } = useBoolState(false, 'confirmDialogOpened');

  const { data: garage } = useFetchAdministratedGarages();
  const { mutateAsync: sendSupportRequest } = useGarageSendSupportRequest(dispatch);

  const openMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorElContactBtn(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorElContactBtn(null);
  }, []);

  const toggleConfirmDialogOpenedProxy = useCallback(() => {
    toggleConfirmDialogOpened();
    closeMenu();
  }, [closeMenu, toggleConfirmDialogOpened]);

  const onSendMessage: FormikConfig<{ message: string }>['onSubmit'] = useCallback(
    async ({ message }, formikHelpers) => {
      invariant(garage?.id, 'Garage ID is required to send a message');
      // Envoyer le message
      await sendSupportRequest({ garageId: garage.id, message });
      formikHelpers.resetForm();
      toggleConfirmDialogOpened();
    },
    [garage?.id, sendSupportRequest, toggleConfirmDialogOpened],
  );

  const { values, errors, handleChange, handleSubmit } = useFormik<{ message: string }>({
    initialValues: {
      message: '',
    },
    onSubmit: onSendMessage,
    validationSchema: Yup.object().shape({
      message: Yup.string().required('Veuillez saisir un message'),
    }),
    enableReinitialize: true,
  });

  return (
    <>
      <Button size='medium' variant='outlined' color='inherit' disableElevation onClick={openMenu}>
        Contacter Movalib
      </Button>
      <Menu
        sx={{ mt: '45px' }}
        id='menu-contact-appbar'
        anchorEl={anchorElContactBtn}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorElContactBtn)}
        onClose={closeMenu}
      >
        <MenuItem sx={{ display: 'flex', gap: '8px', alignItems: 'center' }} onClick={toggleConfirmDialogOpenedProxy}>
          <Message />
          <Typography variant='body2'>Envoyer un message</Typography>
        </MenuItem>
        {garage?.supportPhoneNumber && (
          <Link color='inherit' underline='none' href={`tel:+33${garage.supportPhoneNumber.slice(1)}`}>
            <MenuItem sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <Phone />
              <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                {formatPhoneNumber(garage.supportPhoneNumber)}
              </Typography>
            </MenuItem>
          </Link>
        )}
      </Menu>

      {/* Dialog de saisie/confirmation de l'envoi de message	*/}
      <ConfirmationDialog
        open={isConfirmDialogOpened}
        onClose={toggleConfirmDialogOpened}
        onConfirm={handleSubmit}
        title="Envoyer un message à l'équipe Movalib"
        confirmLabel='Envoyer'
        closeLabel='Annuler'
        message={
          <TextField
            label='Pourquoi souhaitez-vous nous contacter ?'
            name='message'
            value={values.message}
            onChange={handleChange}
            error={!!errors.message}
            helperText={errors.message}
            rows={5}
            autoFocus
            multiline
            fullWidth
          />
        }
        maxWidth='sm'
        fullWidth
      />
    </>
  );
});
