import { formatISO, startOfDay } from 'date-fns';
import type { AnyAction, Dispatch } from 'redux';
import { request } from '../helpers/ApiHelper';
import { API_BASE_URL } from '../helpers/Constants';
import { APIMethod } from '../helpers/Enums';
import type Event from '../models/Event';
import type { UseGenerateEventsProps } from '../query/event/EventQuery';

export default class EventService {
  static closeEvent(dispatch: Dispatch<AnyAction>, eventId: string, req: any): Promise<Event> {
    return request(
      {
        url: `${API_BASE_URL}/events/${eventId}`,
        method: APIMethod.PATCH,
        body: JSON.stringify(req),
      },
      dispatch,
    );
  }

  static updateEvent(dispatch: Dispatch<AnyAction>, eventId: string, req: any): Promise<Event> {
    return request(
      {
        url: `${API_BASE_URL}/events/${eventId}`,
        method: APIMethod.PATCH,
        body: JSON.stringify(req),
      },
      dispatch,
    );
  }

  /**
   * Retourne les événements actifs
   * Un événement est actif si sa date de début est >= à la date du jour
   * @param ownerId
   * @returns
   */
  static getActiveEvents(ownerId: number): Promise<Event[]> {
    const startDate = formatISO(startOfDay(startOfDay(new Date())), { representation: 'date' });

    return request({
      url: `${API_BASE_URL}/events?ownerId=${ownerId}&start_gte=${encodeURIComponent(startDate)}`,
      method: APIMethod.GET,
    });
  }

  static getCalendarEvents(
    dispatch: Dispatch<AnyAction>,
    garageId: number,
    startDate: Date,
    endDate: Date,
  ): Promise<Event[] | null | undefined> {
    return new Promise((resolve, reject) => {
      const startDateWithoutTime = startOfDay(startDate);
      const formattedStartDate = formatISO(startOfDay(startDate), { representation: 'complete' });
      const url = `${API_BASE_URL}/garage/${garageId}/events?startDate=${encodeURIComponent(
        formattedStartDate,
      )}&searchTerm=`;

      request(
        {
          url: url,
          method: APIMethod.GET,
        },
        dispatch,
      )
        .then((data) => {
          if (data) {
            // Filtre les données en fonction des dates de début et de fin
            const filteredData = data
              .filter((event: { startDate: string | number | Date; endDate: string | number | Date }) => {
                // Convertit la date de fin de l'événement en objet Date
                const eventEndDate = new Date(event.endDate);
                // Vérifie si la date de fin de l'événement est dans l'intervalle spécifié
                return eventEndDate >= startDateWithoutTime && eventEndDate <= endDate;
              })
              // Mappe les objets pour les convertir en objets Event
              // Attention a bien reprendre le nom des propriétés de l'objet Event pour ce map
              .map((obj: { startDate: string | number | Date; endDate: string | number | Date }) => ({
                ...obj,
                start: new Date(obj.startDate),
                end: new Date(obj.endDate),
              })) as Event[];
            // Résout la promesse avec les données filtrées
            resolve(filteredData);
          } else {
            // Résout la promesse avec null si aucune donnée n'est renvoyée
            resolve(null);
          }
        })
        .catch((error) => {
          // Rejette la promesse si une erreur se produit dans le bloc try
          reject(error);
        });
    });
  }

  static async generateEvents({ garageId, startDate, endDate }: UseGenerateEventsProps): Promise<void> {
    const url = `${API_BASE_URL}/garage/${garageId}/event/generate`;

    try {
      return request({
        url,
        method: APIMethod.POST,
        body: JSON.stringify({ startDate, endDate }),
      });
    } catch (error) {
      console.error(error);
    }
  }
}
