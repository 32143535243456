import { GarageService, Logger, MovaDialog, MovaFormField, validateEmail, validateField } from '@movalib/movalib-commons';
import { Alert, Box, Button, Grid, TextField, useMediaQuery, useTheme } from '@mui/material';
import { useState, type FC, FormEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../slices/snackbarSlice';
import InfoIcon from '@mui/icons-material/InfoRounded';

interface AddSupplierDialogProps {
    open: boolean,
    garageId: string,
    showHelp?: boolean,
    onClose?: (refresh:boolean) => void;
}

type SupplierForm = {
    name: MovaFormField,
    email: MovaFormField,
  }
  
  const initialFormState = {
    name: { value: '', error: '', isValid: true },
    email: { value: '', error: '', isValid: true },
  };

const AddSupplierDialog: FC<AddSupplierDialogProps> = ({ open, garageId, showHelp = false, onClose }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [form, setForm] = useState<SupplierForm>(initialFormState);
    const [isFormValid, setIsFormValid] = useState(false);
    const dispatch = useDispatch();

    const purgeLocalState = () => {
        setIsFormValid(false);
    }

    const handleOnClose = (refresh:boolean) => {
        purgeLocalState();
        if(onClose){
            onClose(refresh);
        }
    }

    const validateForm = () => {

        let newForm: SupplierForm = { ...form };
    
        // Validator pour les champs obligatoires
        newForm.name = validateField(form.name, value => !!value, 'Champ obligatoire');
        newForm.email = validateField(form.email, value => !!value, 'Champ obligatoire');

        // Validator pour l'email
        newForm.email = validateField(form.email, validateEmail, 'Adresse email invalide');
    
        setForm(newForm);
        let isValid = Boolean(newForm.name.isValid && newForm.email.isValid);

        setIsFormValid(isValid);
    
        return isValid;
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            if(validateForm() && garageId) {
            
                // Ajout du grossiste
                let req = {
                    name: form.name.value,
                    email: form.email.value
                };
        
                GarageService.createGarageSupplier(garageId, req)
                .then(response => {
                    if(response.success){
                        dispatch(setSnackbar({ open: true, message: response.data ?? "Grossiste ajouté avec succès", severity: 'success' }));
                        // On ferme la dialog box avec demande de refresh
                        handleOnClose(true);
                    }else{
                        dispatch(setSnackbar({ open: true, message: response.error ?? "Ajout du grossiste impossible", severity: 'error' }));
                    }
                });
            }

        }catch (error){
            Logger.error('Error occurred during submission:', error);
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const fieldName: string = e.target.name;
        const fieldValue: string = e.target.value;
        const newField: MovaFormField = { [fieldName]: { value: fieldValue, isValid: true } };
  
        setForm({ ...form, ...newField});
      }     

    return (
        <>
            <MovaDialog fullScreen={isMobile} open={open} onClose={() => handleOnClose(false)} leafImageColor="green" 
                closable
                title={"NOUVEAU GROSSISTE"} 
                actions={
                    <Button onClick={handleSubmit} 
                        color="primary" sx={{  width:'90%' }} 
                        disabled={Boolean(form.name.value.length == 0 || form.email.value.length == 0)} 
                        variant='contained'>
                        Ajouter ce grossiste
                    </Button>
                }
            >
                <Grid container>

                    {showHelp && <Alert severity="success" sx={{ mb: 2, borderRadius: 20 }} icon={<InfoIcon />}>
                        Ce grossiste sera disponible sur le détail d'un rendez-vous, au niveau de la colonne "Commande" 😉
                    </Alert>}

                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            required
                            autoFocus
                            fullWidth
                            id="name"
                            label="Nom"
                            name="name"                    
                            onChange={e => handleInputChange(e)}
                            value={form.name.value}
                            error={Boolean(form.name.error)}
                            helperText={form.name.error}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Adresse email"
                            name="email"
                            autoComplete="email"                      
                            onChange={e => handleInputChange(e)}
                            value={form.email.value}
                            error={Boolean(form.email.error)}
                            helperText={form.email.error}
                        />
                    </Grid>
                </Grid>

            </MovaDialog>
        </>
    );
}

export default AddSupplierDialog;
