import React, { FunctionComponent } from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import { readCookie } from './helpers/Tools';
import Cookies from 'js-cookie';
import { COOKIE_IS_AUTHENTICATED, COOKIE_PRO_TOKEN } from './helpers/Constants';
import { RoleType, User } from '@movalib/movalib-commons';

interface PrivateRouteProps extends RouteProps {
  isAccessGranted: boolean | undefined;
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({ component: Component, isAccessGranted: isAccessGranted,  ...rest }) => {
  if (!Component) return null; // Si aucun composant n'est passé, on retourne null
  let isAuthenticated:boolean = Boolean(readCookie(COOKIE_PRO_TOKEN));

  const RenderComponent: FunctionComponent<RouteComponentProps> = (props) => {
    
    if (!isAuthenticated || !isAccessGranted) {    
      return <Redirect to={{ pathname: '/login' }} />
    }

    return <Component {...props} />;
  }

  return <Route {...rest} component={RenderComponent} />;
};
  
export default PrivateRoute;
