import type { Event, Garage } from '@movalib/movalib-commons';
import { formatFrenchVehiclePlate } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import Checkbox from '../../assets/images/icons/pdf-empty-checkbox.png';
import MovalibLeaves from '../../assets/images/logo/leaves_small.png';
import VehicleOR from '../../assets/images/vehicule_or.png';
import theme from '../../theme';
import { Table, TableBody, TableCell, TableCellContainer, TableHeader, TableRow } from './helpers/Table';

Font.register({
  family: 'Outfit',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4TC1C4E.ttf' },
    { src: 'https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4deyC4E.ttf', fontWeight: 'bold' },
    { src: 'https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4TC0C4E.ttf', fontWeight: 'thin' },
  ],
});

const styles = StyleSheet.create({
  container: {
    padding: 25,
    fontFamily: 'Outfit',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 10,
  },
  title: {
    color: '#A1BA50',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 26,
    marginBottom: 5,
  },
  introductionContainer: {
    flexDirection: 'row',
    fontSize: 10,
    marginBottom: 15,
  },
  introductionCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    padding: 5,
  },
  infoCardSection: {
    padding: 15,
    borderRadius: 5,
    height: 75,
    justifyContent: 'center',
  },
  repairOrderTitle: {
    color: '#A1BA50',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 15,
    fontSize: 14,
    display: 'flex',
    flexWrap: 'wrap',
  },
  bold: { fontWeight: 'bold' },
});
const MAX_OPERATIONS_BEFORE_BREAK = 3;

export const RepairOrder = ({
  garage,
  event,
  showInternalNote,
  number,
}: { garage: Garage; event: Event; showInternalNote: boolean; number: number }) => (
  <Document title='Ordre de Réparation'>
    <Page style={styles.container} wrap>
      <Text style={styles.title}>ORDRE DE RÉPARATION N° {number}</Text>

      <View style={styles.introductionContainer}>
        <View style={styles.introductionCard}>
          <Text style={{ color: '#D7B049', fontWeight: 'bold', marginBottom: 4 }}>Du Garage</Text>

          <View style={[styles.infoCardSection, { backgroundColor: theme.palette.secondary.main }]}>
            <Text style={styles.bold}>{garage.name}</Text>
            <Text>{garage.address.streetName}</Text>
            <Text>{`${garage.address.postalCode} ${garage.address.cityName}`}</Text>
            <Text>{`${garage.contactPhone} - ${garage.contactEmail}`}</Text>
          </View>
        </View>

        <View style={styles.introductionCard}>
          <Text style={{ color: '#D38AA5', fontWeight: 'bold', marginBottom: 4 }}>Pour le Client</Text>

          <View style={[styles.infoCardSection, { backgroundColor: '#F29ABA' }]}>
            <Text style={styles.bold}>{`${event.customer?.firstname} ${event.customer?.lastname}`}</Text>
            <Text>{event.customer?.phoneNumber}</Text>
            {event.customer?.email && <Text>{event.customer.email}</Text>}
          </View>
        </View>
      </View>

      <Text style={styles.repairOrderTitle}>{event.title}</Text>

      <Table style={{ fontSize: 8 }}>
        <TableHeader>
          <TableRow style={styles.bold}>
            <TableCell>IDENTIFICATION DU VÉHICULE</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>MARQUE</TableCell>
            <TableCell>MODÈLE</TableCell>
            <TableCell>VERSION</TableCell>
            <TableCell>KILOMÉTRAGE</TableCell>
            <TableCell>IMMATRICULATION</TableCell>
          </TableRow>
          <TableRow style={styles.bold}>
            <TableCell>{event.vehicle?.brand}</TableCell>
            <TableCell>{event.vehicle?.model}</TableCell>
            <TableCell>{event.vehicle?.version}</TableCell>
            <TableCell>{event.vehicle?.currentMileage ? `${event.vehicle?.currentMileage} kms` : ''}</TableCell>
            <TableCellContainer style={{ flexDirection: 'column' }}>
              <Text>{formatFrenchVehiclePlate(event.vehicle?.plate)}</Text>
              {event.vehicle?.vin && <Text style={{ fontWeight: 'normal', fontSize: 7 }}>{event.vehicle.vin}</Text>}
            </TableCellContainer>
          </TableRow>
        </TableHeader>
      </Table>

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          margin: 15,
        }}
      >
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text>Date de l'intervention :</Text>
          <Text style={{ marginLeft: 5, fontWeight: 'bold' }}>{format(event.start!, 'dd/MM/yyyy')}</Text>
        </View>
        <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            <Text>Heure de </Text>
            <Text style={styles.bold}>début</Text>
          </View>
          <Text style={{ marginBottom: 5, color: '#757575' }}>______ h ______</Text>
        </View>
        <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            <Text>Heure de </Text>
            <Text style={styles.bold}>fin</Text>
          </View>
          <Text style={{ marginBottom: 5, color: '#757575' }}>______ h ______</Text>
        </View>
      </View>

      <Table style={{ fontSize: 8 }}>
        <TableHeader>
          <TableRow style={styles.bold}>
            <TableCell>TRAVAUX À EFFECTUER</TableCell>
          </TableRow>
          <TableRow style={styles.bold}>
            <TableCell style={{ textAlign: 'left', flex: 4 }}>Description de l'opération</TableCell>
            <TableCell>Effectuée</TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {(event.operations || []).map((operation, index) => (
            <TableRow key={index}>
              <TableCell style={{ textAlign: 'left', flex: 4 }}>{operation.description}</TableCell>
              <TableCell style={{ alignSelf: 'center' }}>
                <Image src={Checkbox} />
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCellContainer
              style={{
                textAlign: 'left',
                flex: 4,
                height: 100,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <Text>Autres :</Text>
              {showInternalNote && <Text style={{ padding: 4 }}>{event.notes}</Text>}
            </TableCellContainer>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>

      <View
        style={{
          ...((event.operations || []).length < MAX_OPERATIONS_BEFORE_BREAK && {
            position: 'absolute',
            bottom: 10,
            right: 0,
            left: 0,
          }),
          padding: 25,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        wrap={false}
      >
        <View
          style={{
            borderRadius: 5,
            backgroundColor: '#FAFAFA',
            border: '1pt solid #e0e0e0',
            width: '100%',
            height: 100,
            padding: 10,
            marginBottom: 15,
          }}
        >
          <Text style={{ ...styles.bold, textAlign: 'left' }}>Observations</Text>
        </View>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 15,
            width: '100%',
          }}
        >
          <Image src={VehicleOR} style={{ width: '50%' }} />
          <View style={{ display: 'flex', width: '40%', flexDirection: 'column', justifyContent: 'center' }}>
            <View
              style={{
                borderRadius: 5,
                backgroundColor: '#FAFAFA',
                border: '1pt solid #EDEDED',
                flex: 2,
                textAlign: 'center',
                padding: 5,
                paddingBottom: 0,
              }}
            >
              <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Bon pour accord</Text>
              <View style={{ flexDirection: 'row', justifyContent: 'space-around', height: '100%', fontSize: 8 }}>
                <Text style={{ flex: 1, borderRight: '1pt solid #e0e0e0' }}>Le Garage</Text>
                <Text style={{ flex: 1 }}>Le Client</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          bottom: 0,
          right: 0,
          left: 0,
          marginBottom: 10,
        }}
        fixed
      >
        <Image style={{ width: '5%' }} src={MovalibLeaves} />
      </View>
    </Page>
  </Document>
);
