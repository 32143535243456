import { View, Views } from 'react-big-calendar';
import { ActionType } from './Enums';
import { EventState, EventType } from '@movalib/movalib-commons';

/**
 * Nombre de jours supplémentaires à récupérer, nécessaire pour l'affichage des événnements en zone mémo
 */
export const EVENTS_MEMO_OFFSET: number = 14;

export const CUSTOMERS_PER_PAGE = 25;

export const FIRE_EVENT_DEFAULT_HOURS = 48;
export const MOVALIB_APP_BASE_URL: string | undefined = process.env.REACT_APP_MOVALIB_APP_URL;
/**
 * Durée d'expiration des cookies par défaut
 */
export const COOKIE_DEFAULT_EXPIRES: number = 7;
export const COOKIE_PRO_TOKEN: string = 'movalibProToken';
export const COOKIE_IS_AUTHENTICATED: string = 'isAuthenticated';
export const COOKIE_USER_ID: string = 'userId';
export const COOKIE_SELECTED_GARAGE_ID: string = 'selectedGarageId';

export const CALENDAR_DEFAULT_OPENING_HOUR: number = 8;

export const CALENDAR_MIN_TIME_HOUR: number = 7;
export const CALENDAR_MIN_TIME_MIN: number = 0;
export const CALENDAR_MAX_TIME_HOUR: number = 20;
export const CALENDAR_MAX_TIME_MIN: number = 0;
export const WEEK_DAYS: string[] = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
export const API_BASE_URL: string | undefined = process.env.REACT_APP_API_URL; // Saisir " 'http://localhost:3001' " si utilisation de json-server
/**
 * Définition d'une troisième couleur dominante via constante (un thème ne contient que primary et secondary)
 *  */
export const PALETTE_THIRD_COLOR_MAIN: string = '#F29ABA';
export const PALETTE_THIRD_COLOR_LIGHT: string = '#F1C0D9';

export const DEFAULT_PLANNING_TIMEPICKER_STEP: number = 30;
export const DEFAULT_EVENT_TIMEPICKER_STEP: number = 15;
export const DEFAULT_CALENDAR_STEP: number = 30;
export const DEFAULT_EVENT_COLOR: string = 'grey';
export const DEFAULT_EVENT_TYPE: EventType = EventType.APPOINTMENT;
export const DEFAULT_EVENT_STATE: EventState = EventState.NEW;
export const ID_DEV: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
export const CULTURE: string = 'fr';

/**
 * Jour de fin d'une semaine par défaut (Lundi:0 etc..)
 * TODO: inclure dans les attribut du garage et le récupérer via l'API
 */
export const DEFAULT_END_OF_WEEK: number = 5;
export const DEFAULT_CALENDAR_VIEW: View = Views.WEEK;

export type FormField = {
  value?: any;
  error?: string;
  isValid?: boolean;
};
export { EventState };
