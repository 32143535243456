import { Logger, MovaDialog, type Subscription, SubscriptionState } from '@movalib/movalib-commons';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { type Stripe, loadStripe } from '@stripe/stripe-js';
import { type FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import LogoProLarge from '../assets/images/logo/logo_pro_large_border.png';
import StripePaymentSetupForm from '../components/stripe/StripePaymentSetupForm';
import SubscriptionService from '../services/SubscriptionService';
import { setSnackbar } from '../slices/snackbarSlice';

interface ActivateSubscriptionDialogProps {
  open: boolean;
  closable?: boolean;
  onClose?: (refresh: boolean) => void;
}

const ActivateSubscriptionDialog: FC<ActivateSubscriptionDialogProps> = ({ open, closable = true, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
  const token = useRef<string>();
  const subscriptionId = useRef<string>();
  const [subscription, setSubscription] = useState<Subscription>();

  useEffect(() => {
    const params = location !== undefined ? new URLSearchParams(location.search) : undefined;
    subscriptionId.current = params?.get('subscription') ?? '';
    token.current = params?.get('token') ?? '';

    if (subscriptionId.current !== '' && token.current !== '') {
      loadSubscription();

      // Récupération de la clé API publishable pour chargement du Promise Stripe
      SubscriptionService.getStripePublishableKey(token.current, subscriptionId.current)
        .then((response) => {
          if (response.success && response.data) {
            setStripePromise(loadStripe(response.data));
          } else {
            Logger.error(response.error);
            dispatch(
              setSnackbar({ open: true, message: response.error ?? 'Une erreur est survenue', severity: 'error' }),
            );
          }
        })
        .catch((error) => {
          Logger.error(error);
        });
    }
  }, []);

  const loadSubscription = () => {
    if (token.current && subscriptionId.current) {
      // Chargement de la souscription et initialisation du formulaire
      SubscriptionService.getSubscription(token.current, subscriptionId.current).then((response) => {
        if (response.success) {
          setSubscription(response.data);
        } else {
          setSubscription(undefined);
        }
      });
    }
  };

  const handleOnClose = (refresh: boolean) => {
    if (onClose) {
      onClose(refresh);
    }
  };

  return (
    <>
      <MovaDialog
        fullScreen={isMobile}
        open={open}
        onClose={() => handleOnClose(false)}
        leafImageColor='green'
        maxWidth='sm'
        closable={closable}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={LogoProLarge} style={{ width: '40%' }} />
            <br />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ mt: 1, mb: 1 }} color={theme.palette.primary.dark}>
            <b>MANDAT DE PRÉLÈVEMENT SEPA</b>
          </Typography>
          {subscription?.state !== SubscriptionState.ACTIVE && (
            <Typography sx={{ mt: 1, mb: 1 }} color={theme.palette.primary.dark}>
              Inscrivez votre forme légale à la suite du nom (SARL, SAS etc...)
            </Typography>
          )}
        </Grid>
        {stripePromise !== undefined && subscription && token.current && (
          <Elements stripe={stripePromise}>
            <StripePaymentSetupForm token={token.current} subscription={subscription} />
          </Elements>
        )}
      </MovaDialog>
    </>
  );
};

export default ActivateSubscriptionDialog;
