import { Box, type SxProps, type Theme } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import type { FunctionComponent } from 'react';
import { DEFAULT_EVENT_TIMEPICKER_STEP } from '../helpers/Constants';

interface DateTimePickerGroupProps {
  disableTimePicker?: boolean;
  dateLabel: string;
  timeLabel: string;
  value: Date | null | undefined;
  onChange: (newValue: Date | null) => void;
  marginBottom?: number;
  sx?: SxProps<Theme>; // Ajout de la propriété sx
}

const DateTimePickerGroup: FunctionComponent<DateTimePickerGroupProps> = ({
  dateLabel,
  timeLabel,
  value,
  onChange,
  marginBottom = 0,
  sx,
  disableTimePicker,
}) => {
  return (
    <Box display={'flex'} sx={{ mb: marginBottom, ...sx }}>
      {/* Utilisation de la propriété sx */}
      <Box sx={{ mr: 3 }}>
        <DatePicker
          label={dateLabel}
          value={value}
          format='dd/MM/yyyy'
          formatDensity='dense'
          views={['day']}
          displayWeekNumber
          onChange={onChange}
          slotProps={{
            textField: {
              required: true,
              size: 'small',
              sx: {
                padding: 0,
              },
            },
          }}
        />
      </Box>
      <Box>
        <TimePicker
          minutesStep={DEFAULT_EVENT_TIMEPICKER_STEP}
          label={timeLabel}
          value={value}
          disabled={disableTimePicker}
          formatDensity='dense'
          onChange={onChange}
          slotProps={{
            textField: {
              required: true,
              size: 'small',
              sx: {
                padding: 0,
              },
            },
            actionBar: {
              sx: { display: 'none' },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default DateTimePickerGroup;
