import { IbanElement } from '@stripe/react-stripe-js';
import type React from 'react';
import { useEffect, useState } from 'react';
import './IbanFormStyles.css';
import {
  Logger,
  type MovaFormField,
  type Subscription,
  SubscriptionState,
  flexLeftRow,
  validateField,
} from '@movalib/movalib-commons';
import { flexCenter } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import { LoadingButton } from '@mui/lab';
import { Alert, Grid, TextField, Typography } from '@mui/material';
import type { StripeIbanElementOptions } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import SubscriptionService from '../../services/SubscriptionService';
import theme from '../../theme';

// Définition des types pour les styles personnalisés des éléments IBAN.
interface StyleOptions {
  base: {
    iconColor: string;
    color: string;
    fontSize: string;
    '::placeholder': {
      color: string;
    };
    ':-webkit-autofill': {
      color: string;
    };
  };
  invalid: {
    color: string;
    iconColor: string;
    ':-webkit-autofill': {
      color: string;
    };
  };
}

// Options pour l'élément IBAN avec le typage.
const IBAN_STYLE: StyleOptions = {
  base: {
    color: theme.palette.text.primary,
    iconColor: theme.palette.text.primary,
    fontSize: '16px',
    '::placeholder': {
      color: theme.palette.grey[400],
    },
    ':-webkit-autofill': {
      color: theme.palette.grey[400],
    },
  },
  invalid: {
    color: '#d32f3f',
    iconColor: '#d32f3f',
    ':-webkit-autofill': {
      color: '#d32f3f',
    },
  },
};

const IBAN_ELEMENT_OPTIONS: StripeIbanElementOptions = {
  supportedCountries: ['SEPA'],
  placeholderCountry: 'FR',
  style: IBAN_STYLE,
};

// Définition du type pour les props de IbanForm
interface StripeIbanFormProps {
  token: string;
  subscriptionId: string;
  onSubmit: (companyName: MovaFormField, paymentEmail: MovaFormField) => Promise<boolean>;
  disabled: boolean;
}

type Form = {
  companyName: MovaFormField;
  companyEmail: MovaFormField;
  paymentIban: MovaFormField;
};

const initialFormState = {
  companyName: { value: '', error: '', isValid: true },
  paymentIban: { value: '', error: '', isValid: true },
  companyEmail: { value: '', error: '', isValid: true },
};

const StripeIbanForm: React.FC<StripeIbanFormProps> = ({ token, subscriptionId, onSubmit, disabled }) => {
  const [form, setForm] = useState<Form>(initialFormState);
  const [loading, setLoading] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<Subscription>();
  const history = useHistory();

  useEffect(() => {
    // Chargement de la souscription et initialisation du formulaire
    SubscriptionService.getSubscription(token, subscriptionId)
      .then((response) => {
        if (response.success && response.data) {
          const subscription: Subscription = response.data;

          setForm((prevForm) => ({
            ...prevForm,
            companyName: { ...prevForm['companyName'], value: subscription.companyName },
          }));
          setForm((prevForm) => ({
            ...prevForm,
            companyEmail: { ...prevForm['companyEmail'], value: subscription.companyEmail },
          }));

          setSubscription(subscription);
        } else {
          Logger.error(response.error);
        }
      })
      .catch((error) => {
        Logger.error(error);
      });
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const fieldName: string = e.target.name;
    const fieldValue: string = e.target.value;

    // Gérer les autres champs ici, si nécessaire
    const newField: MovaFormField = { [fieldName]: { value: fieldValue, isValid: true } };
    setForm({ ...form, ...newField });
  };

  const validateForm = () => {
    const newForm: Form = { ...form };

    // Validator pour les champs obligatoires
    newForm.companyName = validateField(form.companyName, (value) => !!value && value !== '', 'Champ obligatoire');
    newForm.companyEmail = validateField(form.companyEmail, (value) => !!value && value !== '', 'Champ obligatoire');

    setForm(newForm);

    Logger.info(newForm);

    return Boolean(newForm.companyName.isValid && newForm.companyEmail.isValid);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      if (validateForm() && onSubmit) {
        const response = await onSubmit(form.companyName, form.companyEmail);
        // Soumission concluante
        if (response) {
          // Chargement de la souscription et initialisation du formulaire
          const subscription = await SubscriptionService.getSubscription(token, subscriptionId);
          if (subscription.success) {
            setSubscription(subscription.data);
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      Logger.error('Error occurred during submission:', error);
      setLoading(false);
    }
  };

  return (
    <>
      {subscription?.state === SubscriptionState.PENDING && (
        <Grid container>
          <Grid item xs={12}>
            <TextField
              autoFocus
              margin='normal'
              variant='standard'
              fullWidth
              id='companyName'
              label="Nom de l'entreprise"
              name='companyName'
              onChange={(e) => handleInputChange(e)}
              value={form.companyName.value}
              error={Boolean(form.companyName.error)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin='normal'
              variant='standard'
              fullWidth
              id='companyEmail'
              label="Email de l'entreprise"
              name='companyEmail'
              placeholder='HELLO'
              onChange={(e) => handleInputChange(e)}
              value={form.companyEmail.value}
              error={Boolean(form.companyEmail.error)}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Typography style={flexLeftRow} color={'text.secondary'} sx={{ mb: 1 }}>
              IBAN de l'entreprise
            </Typography>
            <IbanElement options={IBAN_ELEMENT_OPTIONS} />
          </Grid>
          <Grid item xs={12} sx={{ mt: 4 }}>
            <LoadingButton loading={loading} variant='contained' onClick={(e) => handleSubmit()} disabled={disabled}>
              J'accepte le mandat de prélèvement automatique
            </LoadingButton>
          </Grid>
          <Grid item xs={12}>
            <Alert sx={{ fontSize: '0.8rem', mt: 2 }} icon={<></>}>
              En fournissant vos informations de paiement et en confirmant ce paiement, vous autorisez (A){' '}
              <b>MOVALIB SAS</b> et Stripe, notre prestataire de services de paiement et/ou PPRO, son prestataire de
              services local, à envoyer des instructions à votre banque pour débiter votre compte et (B) votre banque à
              débiter votre compte conformément à ces instructions. Vous avez, entre autres, le droit de vous faire
              rembourser par votre banque selon les modalités et conditions du contrat conclu avec votre banque. La
              demande de remboursement doit être soumise dans un délai de 8 semaines à compter de la date à laquelle
              votre compte a été débité. Vos droits sont expliqués dans une déclaration disponible auprès de votre
              banque. Vous acceptez de recevoir des notifications des débits à venir dans les 2 jours précédant leur
              réalisation.
            </Alert>
          </Grid>
        </Grid>
      )}
       
      {subscription?.state === SubscriptionState.ACTIVE && (
        <Grid container>
          <Grid item xs={12} style={flexCenter}>
            <Alert sx={{ fontSize: '1rem', mt: 1, mb: 2 }} icon={<></>} className='styled-alert'>
              Votre mandat de prélèvement a bien été accepté ! <br />
            </Alert>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default StripeIbanForm;
