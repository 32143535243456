import React, { useEffect, useRef, useState } from 'react';
import Avatar from 'react-avatar';
import { Box, Button, Input, Typography } from '@mui/material';
import { Logger } from '@movalib/movalib-commons';

interface AvatarSelectorProps {
  firstName: string; // Prénom de l'utilisateur
  lastName: string;  // Nom de famille de l'utilisateur
  avatarBase64?: string; // Avatar de l'utilisateur en base64
  editMode?: boolean;
  onChange?: (avatarBase64: string | null) => void,  // Callback pour renvoyer l'avatar converti en image base64
}

const AvatarSelector: React.FC<AvatarSelectorProps> = ({ firstName, lastName, avatarBase64, editMode = false, onChange }) => {
  const [color, setColor] = useState<string>('#00BFFF'); // Couleur initiale de l'avatar
  const [avatar, setAvatar] = useState<string | null>(avatarBase64 || null);

  useEffect(() => {
    if (!editMode) {
      handleGetAvatarBase64();
    }
  }, [firstName, lastName, color])

  useEffect(() => {
    if(onChange){
      onChange(avatar);
    }
  }, [avatar])

  // Générer les initiales à partir du prénom et du nom
  const getInitials = (firstName: string, lastName: string) => {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  };

  const handleGetAvatarBase64 = () => {
    const canvas = document.createElement('canvas');
    canvas.width = 100;
    canvas.height = 100;
    
    const context = canvas.getContext('2d');
    if (!context) return;

    context.fillStyle = color;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = "33px sans-serif";
    context.fillStyle = 'white';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(getInitials(firstName, lastName), canvas.width / 2, canvas.height / 2);
    
    const dataURL = canvas.toDataURL('image/png');
    setAvatar(dataURL);
  };

  // Fonction pour récupérer l'avatar sous forme de base64
  const handleGetAvatarBase642 = () => {
    const canvas = document.createElement('canvas');
    const avatarElement = document.querySelector('.sb-avatar') as HTMLElement;
    if (!avatarElement) { return; }

    const avatarText = avatarElement.querySelector('.sb-avatar__text');
    if (!avatarText) { return; }

    const avatarTextClone = avatarText.cloneNode(true) as HTMLElement;
    canvas.width = avatarElement.offsetWidth;
    canvas.height = avatarElement.offsetHeight;
    
    const context = canvas.getContext('2d');
    if (!context) { return; }

    context.fillStyle = window.getComputedStyle(avatarText).backgroundColor || 'transparent';
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = `33px ${window.getComputedStyle(avatarText).fontFamily}`;
    context.fillStyle = window.getComputedStyle(avatarText).color || 'black';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(avatarTextClone.innerText, canvas.width / 2, canvas.height / 2);
    
    const dataURL = canvas.toDataURL('image/png');
    setAvatar(dataURL);
  };

  const handleChangeAvatarColor = (e: React.FormEvent<HTMLDivElement>) => {
    const inputValue = (e.target as HTMLInputElement).value;
    setColor(inputValue);
    handleGetAvatarBase64();
  };

  return (
    <Box textAlign="center">
      <Box mb={2}>
        {/* React Avatar pour générer l'avatar avec la couleur sélectionnée */}
        {avatar ? (
          <img src={avatar} alt="Avatar" style={{ borderRadius: '50%', width: '100px', height: '100px' }} />
          ) : (
          <Avatar name={getInitials(firstName, lastName)} color={color} size="100" round={true} maxInitials={2}
          initials={getInitials(firstName, lastName)} />
          )}
      </Box>
      <Box mt={2}>
        <Typography variant='body2'>Cliquez ci-dessous pour modifier</Typography>
        {/* Sélecteur de couleur pour changer la couleur de l'avatar */}
        <Input
          type="color"
          value={color}
          onClick={(e) => handleChangeAvatarColor(e)}
          onInput={(e) => handleChangeAvatarColor(e)}
          onChange={(e) => setColor(e.target.value)}
          sx={{ width: 100 }}
          inputProps={{
            sx:{cursor:'pointer'}
          }}
        />
      </Box>


{/*   ACTIVER POUR TESTER LA GENERATION DE L'AVATAR EN BASE 64

      <Button onClick={handleGetAvatarBase64}>Get Avatar Base64</Button>
      {avatarBase64 && (
        <div>
          <h2>Avatar en base64 :</h2>
          <img src={avatarBase64} alt="Avatar" />
        </div>
      )} */}

    </Box>
  );
};

export default AvatarSelector;
