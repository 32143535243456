import React, { ChangeEvent, FunctionComponent, useState } from "react";
import { Box, Typography } from "@mui/material";
import defaultImage from "../assets/images/default_image.png";
import theme from '../theme'; // Import du thème personnalisé

interface ImageUploadProps {
    title:string,
    onUpload: (file: File) => void;
}
  
const ImageUpload: FunctionComponent<ImageUploadProps> = ({ title = "Ajouter une photo", onUpload }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(defaultImage);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImageSrc(URL.createObjectURL(file));
      onUpload(file);
    }
  };

  return (
    <Box
      component="label"  // Transform the box into a label
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        backgroundImage: `url(${imageSrc})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: 300,  // Set the height you prefer
        width: 300,  // Set the width you prefer
        borderRadius: "8px",  // Optional rounded corners
      }}
    >
      <Typography color={theme.palette.text.secondary} variant='button'>
        {title}
      </Typography>
      <input
        type="file"
        accept="image/*"
        hidden  // Hide the ugly default file input
        onChange={handleFileChange}
      />
    </Box>
  );
};

export default ImageUpload;
