import { Box, Card, CardContent, Paper, SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import type { FC } from 'react';
import InfoIcon from '@mui/icons-material/InfoRounded';
import { getRotatedIconStyle } from '../helpers/Tools';
import React from 'react';

interface EmptyCardProps {
    message?: string
    sx?: SxProps<Theme>;
}   

const EmptyCard: FC<EmptyCardProps> = ({ sx, message = "Aucun résultat" }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Paper variant='outlined' sx={{ ...sx, overflow: 'visible',  mx: isMobile ? 0 : 2 , backgroundColor: 'white', pb: 1, pt: 1 }}>
                <Typography variant="subtitle1" component="div" align="center" 
                    sx={{ color: theme.palette.grey[400]  }}>
                    {message}
                </Typography>
            </Paper>    
        </>
    );
}

export default EmptyCard;
