import { Alert, AlertColor, Box, Button, Checkbox, Container, CssBaseline, FormControlLabel, Grid, Link, TextField, Typography } from '@mui/material';
import React, { CSSProperties, FormEvent, FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import MyLoadingButton from '../components/MyLoadingButton';
import { ButtonType } from '../helpers/Enums';
import { useDispatch } from 'react-redux';
import { setGarageData } from '../slices/garageSlice';
import GarageService from '../services/GarageService';
import { LoadingButton } from '@mui/lab';
import { setUserData, setAuthenticatedState } from '../slices/userSlice';
import Cookies from 'js-cookie';
import { COOKIE_IS_AUTHENTICATED, COOKIE_PRO_TOKEN, COOKIE_USER_ID } from '../helpers/Constants';
import { createCookie } from '../helpers/Tools';
import { AuthenticationService, Logger, MovaAppType, MovaLogin, MovaLoginForm, User, deleteCookie } from '@movalib/movalib-commons';
import useDarkMode from '../helpers/hooks/useDarkMode';
import { setSnackbar } from '../slices/snackbarSlice';

const Login: FunctionComponent = () => {

    const dispatch = useDispatch();
    const isDarkMode = useDarkMode();
    const history = useHistory();
    const [alertMessage, setAlertMessage] = useState<string>("");    
    // La sévérité est initialisée à "error" par défaut
    const [alertSeverity, setAlertSeverity] = useState<AlertColor>('error');    
    const [loading, setLoading] = useState(false);

    const handleLoginSuccess = (user:User | undefined) => {

        // On sauvegarde l'état dans le state global (info non persistante)
        dispatch(setAuthenticatedState(true));

        // On sauvegarde l'identifiant dans un cookie
        createCookie(COOKIE_USER_ID, user ? user.id : '');
    }
    
    const handleSubmit = async (form: MovaLoginForm) => {
        setLoading(true);

        try {

            if(form){

                deleteCookie(COOKIE_PRO_TOKEN);

                AuthenticationService.login(MovaAppType.GARAGE, form.email.value, form.password.value)
                .then(response => {

                    if(response.success){

                        Logger.info(User.isSales(response.data as User))
                        // On charge les données utilisateur dans le state global
                        dispatch(setUserData(response.data ?? null));
                        // On gère le succès de l'authentification
                        handleLoginSuccess(response.data);

                        if(User.isSales(response.data as User)) {

                            // On redirige l'utilisateur vers la page par défaut
                            history.push('/registrations');

                        } else if(User.isGarageAdmin(response.data as User)){

                            // On charge les données du garage administré dans le state global
                            // MVP : un seul garage possible pour le moment, exception le cas contraire
                            GarageService.getAdministratedGarages(dispatch)
                            .then(garage => {

                                Logger.info(garage);

                                if(!garage){
                                    setAlertMessage("Erreur lors du chargement du garage.");
                                    return;
                                }

                                // Vérification de la présence de plusieurs garages
                                if(Array.isArray(garage) && garage.length > 1) {
                                    setAlertMessage("Plusieurs garages trouvés. Vous ne pouvez gérer qu'un seul garage pour le moment.");
                                    return;
                                }

                                // Si tout va bien, on prend le premier garage (ou le seul garage)
                                const firstGarage = Array.isArray(garage) ? garage[0] : garage;

                                // On charge les données garage dans le state global
                                dispatch(setGarageData(firstGarage));
        
                            }).catch(error => {
                                Logger.error(error);
                                setAlertMessage("Erreur lors du chargement du garage.");
                            });
    
                            // On redirige l'utilisateur vers la page par défaut
                            history.push('/agenda');

                        } 
                        
                    } else {
                        setAlertMessage(response.error ?? "Connexion impossible, contactez l'équipe Movalib");
                    }
                    
                }).catch(error => {
                    setAlertMessage(error);
                });
            }

        }catch (error){
            console.error('Error occurred during submission:', error);
        }finally {
            setLoading(false);
        }
    }

    const handlOnSubmitForgotPassword = async (email: string) => {
        if(email){

            let query = {
                phoneEmail: email,
                appType: "MOVALIB_PRO"
            }

            AuthenticationService.forgotPassword(query)
            .then(response => {
                if(response.success){
                    dispatch(setSnackbar({ open: true, message: response.data ?? "Mot de passe réinitialisé ! (consultez vos emails)", severity: 'success' }));
                }else{
                    dispatch(setSnackbar({ open: true, message: response.error ?? "Problème lors de la réinitialisation du mot de passe, contactez Movalib", severity: 'error' }));
                }
            });
        }
    }

    return (     
            <MovaLogin 
                movaAppType={MovaAppType.GARAGE} 
                onSubmit={handleSubmit} 
                onSubmitForgotPassword={handlOnSubmitForgotPassword}
                alertMessage={alertMessage} 
                alertSeverity={alertSeverity} 
                loading={loading} 
                version={process.env.REACT_APP_VERSION} />
    );
};

export default Login;