import { request } from "../helpers/ApiHelper";
import { API_BASE_URL } from "../helpers/Constants";
import { APIMethod } from "../helpers/Enums";
import { Document, User, Vehicle } from "@movalib/movalib-commons";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";

export default class VehicleService {

    static getVehicleDetails(dispatch: Dispatch<AnyAction>, vehicleId: number): Promise<Vehicle>  {
        return request({
            url: `${API_BASE_URL}/vehicle/${vehicleId}`,
            method: APIMethod.GET
        }, dispatch);
    }

    static updateVehicle(dispatch: Dispatch<AnyAction>, vehicleId: number, req: any): Promise<string> {
        return request({
          url: `${API_BASE_URL}/vehicle/${vehicleId}`,
          method: APIMethod.PATCH,
          body: JSON.stringify(req)
        }, dispatch);
    }

    static deleteVehicleDocument(dispatch: Dispatch<AnyAction>, vehicleId: number, documentId: string): Promise<string> {
        return request({
          url: `${API_BASE_URL}/vehicle/${vehicleId}/documents/${documentId}`,
          method: APIMethod.DELETE
        }, dispatch);
    }

    static uploadVehicleDocument(dispatch: Dispatch<AnyAction>, vehicleId: number, req: FormData): Promise<string> {
        return request({
          url: `${API_BASE_URL}/vehicle/${vehicleId}/documents`,
          method: APIMethod.POST,
          body: req
        }, dispatch);
    }

    static getVehicleDescription(plate: string): Promise<string | null>  {
        return request({
            url: `${API_BASE_URL}/vehicle/${plate}/description`,
            method: APIMethod.GET
        });
    }

    static getVehicleById(id: number): Promise<Vehicle | null>  {
        return request({
            url: `${API_BASE_URL}/vehicles/${id}`,
            method: APIMethod.GET
        });
    }
}