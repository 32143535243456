import { Logger, type MovaFormField, type Subscription } from '@movalib/movalib-commons';
import { IbanElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { StripeClientSecretType } from '../../helpers/Enums';
import type StripeClientSecret from '../../models/StripeClientSecret';
import SubscriptionService from '../../services/SubscriptionService';
import { setSnackbar } from '../../slices/snackbarSlice';
import StripeMandateForm from './StripeMandateForm';

interface StripePaymentSetupFormProps {
  token: string;
  subscription: Subscription;
}

const StripePaymentSetupForm: React.FC<StripePaymentSetupFormProps> = ({ token, subscription }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (companyName: MovaFormField, companyEmail: MovaFormField): Promise<boolean> => {
    if (!stripe || !elements || !subscription) {
      // Stripe.js n'a pas encore été chargé ou nous n'avons pas reçu d'identifiant de souscription
      return Promise.resolve(false);
    }

    try {
      const ibanElement = elements.getElement(IbanElement);

      // Récupération des valeurs depuis les champs du formulaire
      const subscriptionName = companyName.value;
      const subscriptionEmail = companyEmail.value;

      // Récupération du client secret (la souscription ayant déjà été créé lors de l'activation du client)
      const response = await SubscriptionService.getStripeSubscriptionSecret(token, subscription.id);
      if (!response.success || !response.data) {
        Logger.error(response.error);
        return Promise.resolve(false);
      }

      const stripeClientSecret: StripeClientSecret = response.data;
      Logger.info(stripeClientSecret);

      if (ibanElement && stripeClientSecret) {
        // On détermine quel méthode appeler selon le type de SecretClient reçu de l'API
        const confirmIntent =
          stripeClientSecret.type === StripeClientSecretType.SETUP_INTENT
            ? stripe.confirmSepaDebitSetup
            : stripe.confirmSepaDebitPayment;

        const result = await confirmIntent(stripeClientSecret.clientSecret, {
          payment_method: {
            sepa_debit: ibanElement,
            billing_details: {
              name: subscriptionName,
              email: subscriptionEmail,
            },
          },
        });

        if (result.error) {
          dispatch(
            setSnackbar({ open: true, message: result.error.message ?? 'Une erreur est survenue', severity: 'error' }),
          );
          return Promise.resolve(false);
        }

        dispatch(
          setSnackbar({ open: true, message: 'Mandat de prélèvement activé avec succès !', severity: 'success' }),
        );

        const finalResponse = await SubscriptionService.finalizeGarageSubscription(token, subscription.id);
        if (!finalResponse.success) {
          Logger.error(finalResponse.error);
          return Promise.resolve(false);
        }

        Logger.info(finalResponse);
        return Promise.resolve(true);
      }
    } catch (error) {
      Logger.error(error);
      return Promise.resolve(false);
    }

    return Promise.resolve(false);
  };

  return (
    <StripeMandateForm onSubmit={handleSubmit} disabled={!stripe} token={token} subscriptionId={subscription.id} />
  );
};

export default StripePaymentSetupForm;
