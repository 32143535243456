import React, { FunctionComponent, useState } from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import { ButtonType } from "../helpers/Enums";
import { SxProps, Theme } from "@mui/material";

type Props = {
    label:string,
    type:ButtonType,
    onSubmit: () => Promise<void>,  // Spécifie que onSubmit est une fonction asynchrone
    className?: string,
    sx?: SxProps<Theme>;
};

const MyLoadingButton: FunctionComponent<Props> = ({label, type, onSubmit, className, sx}) => {

    const [loading, setLoading] = useState<boolean>(false);
    
    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {

        e.preventDefault();
        setLoading(true);

        try {
                 // Avec ajout d'un Timeout
                const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
                
                // Avec ajout d'un Timeout
                await Promise.all([onSubmit(), delay(400)]);

                // Sans Timeout
                //onSubmit();

                setLoading(false);
           
        }catch (error){
            console.error('Error occurred during submission:', error);

        } finally {
            setLoading(false);
        }
    }

    return (
        <LoadingButton        
            className={className}
            sx={{ ...sx }} 
            loading={loading}
            disabled={loading}  // Désactiver le bouton pendant le chargement
            type="submit"
            variant="contained"
            onClick={(e) => handleSubmit(e)}>
            <span>{label}</span>
        </LoadingButton>
    );
}

export default MyLoadingButton;