import {
  AddRounded,
  ChevronLeft,
  ChevronRight,
  EventNote,
  MoreTime,
  SearchRounded,
} from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/RefreshRounded';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { type FunctionComponent, useCallback, useState } from 'react';
import type { ToolbarProps } from 'react-big-calendar';
import './MyCalendar.css';
import { Event, type Garage } from '@movalib/movalib-commons';
import { LoadingButton } from '@mui/lab';
import { CalendarHolidayDialog } from '../../dialogs/calendar/CalendarHolidayDialog';
import { CalendarToolbarSearchDialog } from '../../dialogs/calendar/CalendarToolbarSearchDialog';
import { DEFAULT_EVENT_STATE, DEFAULT_EVENT_TYPE } from '../../helpers/Constants';
import { findEarliestScheduleTime } from '../../helpers/DateUtils';
import { PeriodType } from '../../helpers/Enums';
import {
  formatLocalDateToISOString,
  getEndOf,
  getStartOf,
  isDemoEnv,
  isEnvProd,
  updateServiceWorker,
} from '../../helpers/Tools';
import { useBoolState } from '../../helpers/hooks/useBoolState';
import { useGenerateEvents } from '../../query/event/EventQuery';
import theme from '../../theme';
import { MyEventDialog } from './MyCalendarTypes';
import MenuIcon from '@mui/icons-material/Menu';

type MyCalendarToolBarProps = {
  garage?: Garage;
  garageId: string;
  selectedEvent?: Event;
  onSelectEvent: (event: Event, dialogType: MyEventDialog) => void;
  onRefresh: () => void;
};

type CombinedProps = MyCalendarToolBarProps & ToolbarProps<Event, object>;

const MyCalendarToolBar: FunctionComponent<CombinedProps> = ({
  view,
  views,
  label,
  localizer: { messages },
  onNavigate,
  onView,
  garage,
  garageId,
  onSelectEvent,
  onRefresh,
  date,
}) => {
  const { isSearchOpen, toggleSearchOpen } = useBoolState(false, 'searchOpen');
  const { isHolidayPopupOpen, toggleHolidayPopupOpen } = useBoolState(false, 'holidayPopupOpen');
  const { mutate: generateEvents, isPending } = useGenerateEvents();

  // Ordre souhaité des clés de vue
  const viewOrder = ['work_week', 'week', 'day', 'month'];

  const handleRefreshEvents = () => {
    // On force la mise à jour du serviceWoker
    if (updateServiceWorker) {
      updateServiceWorker();
    }
    // On appelle le callback de refresh si présent
    if (onRefresh) {
      onRefresh();
    }
  };

  const handleViewChange = (event: SelectChangeEvent<typeof view>) => {
    const selectedView = event.target.value as typeof view;
    onView(selectedView);
  };

  const handleCreateEvent = () => {
    const tmpStartDate = new Date();
    tmpStartDate.setHours(tmpStartDate.getHours(), 0, 0, 0);
    const tmpEndDate = new Date(tmpStartDate);
    tmpEndDate.setHours(tmpStartDate.getHours() + 1, 0, 0, 0);

    // On crée "une coquille vide"
    const newEvent = new Event(
      '',
      1,
      DEFAULT_EVENT_TYPE,
      '',
      '',
      Number(garageId),
      DEFAULT_EVENT_STATE,
      undefined,
      tmpStartDate,
      tmpEndDate,
    );

    onSelectEvent(newEvent, MyEventDialog.CREATE);
  };

  const matchesMediaQuery = useMediaQuery('(max-width:1300px)');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onGenerateEvents = useCallback(() => {
    const [startHour = '00', startMin = '00'] = findEarliestScheduleTime(garage?.schedules || [], 'startTime').split(
      ':',
    );
    const [endHour = '00', endMin = '00'] = findEarliestScheduleTime(garage?.schedules || [], 'endTime').split(':');
    const startDate = getStartOf(date, PeriodType.WEEK);
    const endDate = getEndOf(date, PeriodType.WEEK);

    startDate.setHours(Number(startHour), Number(startMin));
    endDate.setHours(Number(endHour), Number(endMin));

    generateEvents({
      garageId,
      startDate: formatLocalDateToISOString(startDate),
      endDate: formatLocalDateToISOString(endDate),
    });
  }, [date, garage?.schedules, garageId, generateEvents]);

  return (
    <div>
      <Toolbar
        disableGutters
        variant='dense'
        sx={{
          justifyContent: 'flex-start',
          color: '#3C4043',
          borderColor: 'transparent',
          my: 1,
          pl: 1,
        }}
      >
        <Grid container sx={{ flexGrow: 1, display: 'contents' }}>
          <Grid>
            {!matchesMediaQuery && (
              <Button
                variant='contained'
                startIcon={<AddRounded />}
                color='inherit'
                onClick={handleCreateEvent}
                sx={{
                  pr: 2,
                  mr: 4,
                  textTransform: 'none',
                  backgroundColor: 'white',
                }}
              >
                Créer
              </Button>
            )}
            <Button
              size='small'
              variant='outlined'
              color='inherit'
              aria-label='navigate today'
              sx={{
                mr: 2,
                textTransform: 'none',
                fontSize: 14,
                borderColor: '#dadce0',
              }}
              onClick={() => onNavigate('TODAY')}
            >
              <span>Aujourd'hui</span>
            </Button>
            <IconButton onClick={() => onNavigate('PREV')}>
              <ChevronLeft />
            </IconButton>
            <IconButton onClick={() => onNavigate('NEXT')}>
              <ChevronRight />
            </IconButton>
          </Grid>
          <Grid sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
            <Typography
              variant='h6'
              sx={{
                display: 'inline',
                justifyContent: 'center',
                flexGrow: 1,
                pl: 2,
              }}
            >
              {label}
            </Typography>
          </Grid>
          <Grid>
            {!matchesMediaQuery && (
              <>
                {(!isEnvProd() || isDemoEnv()) && view === 'week' && (
                  <LoadingButton
                    size='small'
                    variant='contained'
                    startIcon={<EventNote />}
                    color='inherit'
                    onClick={onGenerateEvents}
                    sx={{ mr: 2, backgroundColor: theme.palette.common.white }}
                    loading={isPending}
                  >
                    Créer une semaine type
                  </LoadingButton>
                )}
                <Button
                  size='small'
                  variant='contained'
                  startIcon={<MoreTime />}
                  color='inherit'
                  onClick={toggleHolidayPopupOpen}
                  sx={{ mr: 2, backgroundColor: theme.palette.common.white }}
                >
                  Congés
                </Button>
                <Button
                  size='small'
                  sx={{ mr: 1, fontSize: 14, borderColor: '#dadce0' }}
                  variant='outlined'
                  onClick={toggleSearchOpen}
                  color='inherit'
                >
                  <SearchRounded style={{ fontSize: 20 }} />
                  &nbsp;Recherche RDV
                </Button>
                <Tooltip title='Actualiser'>
                  <IconButton sx={{ mr: 2 }} onClick={handleRefreshEvents}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}

            {matchesMediaQuery && (
              <>
                <Button
                  variant='contained'
                  color='inherit'
                  sx={{ backgroundColor: 'transparent', mr: 2 }}
                  aria-controls={openMenu ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={openMenu ? 'true' : undefined}
                  onClick={handleClickMenu}
                >
                  <MenuIcon />
                </Button>

                <Menu
                  id='basic-menu'
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleCreateEvent();
                      handleCloseMenu();
                    }}
                  >
                    <ListItemIcon>
                      <AddRounded />
                    </ListItemIcon>
                    <ListItemText>Creer RDV</ListItemText>
                  </MenuItem>

                  <MenuItem onClick={onGenerateEvents}>
                    <ListItemIcon>
                      <EventNote />
                    </ListItemIcon>
                    <ListItemText>Créer une semaine type</ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      toggleHolidayPopupOpen();
                      handleCloseMenu();
                    }}
                  >
                    <ListItemIcon>
                      <MoreTime />
                    </ListItemIcon>
                    <ListItemText>Congés</ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      toggleSearchOpen();
                      handleCloseMenu();
                    }}
                  >
                    <ListItemIcon>
                      <SearchRounded />
                    </ListItemIcon>
                    <ListItemText>Recherche RDV</ListItemText>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Grid>

          <FormControl size='small' sx={{ mr: 2, display: { sm: 'block' }, float: { sm: 'right' } }}>
            <Select
              value={view}
              onChange={handleViewChange}
              sx={{
                fontSize: 14,
              }}
            >
              {viewOrder
                .filter(
                  (key) =>
                    !(
                      key === 'work_week' &&
                      (!(garage?.teamManagementActive && garage?.employees) || garage?.employees?.length === 0)
                    ),
                )
                .map((viewKey) => {
                  const viewName = messages[viewKey as keyof typeof views];
                  return (
                    <MenuItem key={viewKey} value={viewKey}>
                      {viewName}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
      </Toolbar>

      {/** Dialog pour créer une période d'abscence */}
      <CalendarHolidayDialog
        garageId={garageId}
        isDialogOpen={isHolidayPopupOpen}
        onCloseDialog={toggleHolidayPopupOpen}
        refreshEvents={handleRefreshEvents}
      />

      {/** Dialog pour rechercher un rendez-vous */}
      <CalendarToolbarSearchDialog
        garageId={garageId}
        isSearchOpen={isSearchOpen}
        toggleSearchOpen={toggleSearchOpen}
        onSelectEvent={onSelectEvent}
      />
    </div>
  );
};

export default MyCalendarToolBar;
