import { Container, CssBaseline } from '@mui/material';
import React, { Fragment, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
  
const PageNotFound: FunctionComponent = () => {
  
  return (
    <Fragment>
      <Container sx= {{
        textAlign: 'center',
        mt: 12
      }}>
          <CssBaseline />
          <h1>Hey, cette page n'existe pas !</h1> 
          <Link to="/agenda">
            Retour à l'agenda
          </Link>
      </Container>
    </Fragment>
  );
}
  
export default PageNotFound;