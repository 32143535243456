import { GarageService } from '@movalib/movalib-commons';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../slices/snackbarSlice';
import { EMPLOYEES } from './EmployeeQueryKeys';

export const useFetchEmployees = (garageId?: string) => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: [EMPLOYEES, garageId],
    queryFn: async () => {
      if (!garageId) {
        return [];
      }

      const { data, success } = await GarageService.getEmployees(garageId);

      if (!success) {
        dispatch(
          setSnackbar({
            open: true,
            severity: 'error',
            message: 'Erreur lors de la récupération des réparateurs',
          }),
        );
        return [];
      }

      return data || [];
    },
  });
};
