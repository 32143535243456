import { type Garage, GarageService } from '@movalib/movalib-commons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { Dispatch } from 'redux';
import Logger from '../../helpers/Logger';
import { setSnackbar } from '../../slices/snackbarSlice';
import { CUSTOMERS } from '../customer/CustomerQueryKeys';
import { GARAGES } from './GarageQueryKeys';

export const useFetchAdministratedGarages = () =>
  useQuery({
    queryKey: [GARAGES],
    queryFn: async () => {
      const { data: garages } = await GarageService.getAdministratedGarages();

      Logger.info(garages ? garages[0] : null);

      // On ne gère que le premier résultat de la liste (MVP : 1 garage)
      return garages?.length ? garages[0] : undefined;
    },
  });

type UseGarageSendSupportRequestProps = {
  message: string;
  garageId: Garage['id'];
};

export const useGarageSendSupportRequest = (dispatch: Dispatch) =>
  useMutation({
    mutationFn: async ({ garageId, message }: UseGarageSendSupportRequestProps) => {
      const response = await GarageService.sendGarageSupportRequest(garageId, { message });
      if (response.success) {
        dispatch(setSnackbar({ open: true, message: 'Message envoyé !', severity: 'success' }));
      }

      if (response.error) {
        dispatch(
          setSnackbar({
            open: true,
            message: response.error,
            severity: 'error',
          }),
        );
      }
    },
  });

export const useGarageAddCustomerVehicle = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: GarageService.addCustomerVehicle,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [CUSTOMERS] });
    },
  });
};

export const useGarageDeleteCustomerVehicle = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: GarageService.deleteCustomerVehicle,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [CUSTOMERS] });
    },
  });
};
