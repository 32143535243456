export enum NotificationType {
  NONE = 'NONE',
  SMS = 'SMS',
  MAIL =  'MAIL'
}

export type ViewType = 'month' | 'week' | 'work_week' | 'day' | 'agenda';

export enum MyEventType {
  APPOINTMENT = 'APPOINTMENT',
  UNAVAILABILITY = 'UNAVAILABILITY',
  NOTE = 'NOTE',
}

export enum MyEventDialog {
  CREATE = 0,
  DETAILS = 1,
  UPDATE = 2,
}

export enum MyEventState {
  /**
   * Nouvelle demande de rendez-vous (origine client OU centre)
   */
  NEW = 'NEW',
  /**
   * Accepté (rendez-vous accepté par le centre)
   * Note : il s'agit du statut par défaut en cas de création du rendez-vous par le centre
   */
  ACCEPTED = 'ACCEPTED',
  /**
   * Planifé (rendez-vous planifié, devis accepté par le client le cas échéant)
   * Note : si l'acceptation du devis n'est pas obligatoire pour le centre,
   * le rendez-vous passe directement à l'état 'SCHEDULED' lorsqu'il est confirmé
   */
  SCHEDULED = 'SCHEDULED',
  /**
   * Complété (les pièces sont disponibles, état de validation supplémentaire indicatif pour le centre)
   */
  COMPLETED = 'COMPLETED',
  /**
   * Le rendez-vous est clôturé
   */
  DONE = 'DONE',
  /**
   * Le rendez-vous a été annulé (par le centre ou le client)
   */
  CANCELLED = 'CANCELLED',
}
