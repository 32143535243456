import { format, startOfDay } from 'date-fns';
import { fr } from 'date-fns/locale';
import { startCase } from 'lodash';
import React from 'react';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import { navigate } from 'react-big-calendar/lib/utils/constants';
import { DEFAULT_CALENDAR_STEP } from '../../helpers/Constants';

export default class MyCalendarDayView extends React.Component {
  render() {
    const {
      date,
      localizer,
      min = localizer.startOf(new Date(), 'day'),
      max = localizer.endOf(new Date(), 'day'),
    } = this.props;

    const range = MyCalendarDayView.range(date, { localizer: localizer });
    return (
      <TimeGrid
        {...this.props}
        range={range}
        min={min}
        max={max}
        eventOffset={DEFAULT_CALENDAR_STEP}
        localizer={localizer}
        scrollToTime={localizer.startOf(new Date(), 'day')}
      />
    );
  }
}

MyCalendarDayView.range = (date, { localizer }) => {
  return [localizer.startOf(date, 'day')];
};

MyCalendarDayView.navigate = (date, action, { localizer }) => {
  switch (action) {
    case navigate.PREVIOUS:
      return localizer.add(date, -1, 'day');

    case navigate.NEXT:
      return localizer.add(date, 1, 'day');

    default:
      return date;
  }
};

MyCalendarDayView.title = (date) => startCase(format(startOfDay(date), 'eeee dd MMMM', { locale: fr }));
