// MySnackBar.tsx

import { Snackbar, Alert, IconButton, SnackbarCloseReason } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { setSnackbar } from '../slices/snackbarSlice';
import CloseIcon from '@mui/icons-material/CloseRounded';

const MovaSnackbar = () => {

  const snackbar = useSelector((state: RootState) => state.snackbar);
  const dispatch = useDispatch();

  /**
   * Gestion de la fermeture de la snackbar. Si la raison de cette fermeture est
   * un clic en dehors de la zone de contenu on intercepte et annule la fermeture 'clickaway'.
   * @param reason Origine de l'événnement de fermeture
   * @returns 
   */
  const handleClose = (reason?: SnackbarCloseReason) => {
    
      if (reason === 'clickaway') return;

      dispatch(setSnackbar({ open: false, message: '', severity: snackbar.severity }));
  };

  return (
    <Snackbar open={snackbar.open} autoHideDuration={5000} onClose={(e, reason) => handleClose(reason)}>
      <Alert severity={snackbar.severity} variant="filled" sx={{ width: '100%' }}
        action={
            <>
              {/* TODO : possiblité d'annuler l'action qui vient d'avoir lieu */}
            {/*   <Button color="inherit" size="small" onClick={handleCancelAction}>
                Annuler
              </Button> */}
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={(e) => handleClose()}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
        }>
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
};

export default MovaSnackbar;
