import { type Event, EventState, EventType, type Garage } from '@movalib/movalib-commons';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  type IconButtonProps,
  List,
  ListItem,
  Typography,
  styled,
} from '@mui/material';
import type React from 'react';
import { type CSSProperties, memo, useState } from 'react';
import type { View } from 'react-big-calendar';
import { PALETTE_THIRD_COLOR_MAIN } from '../../helpers/Constants';
import { sortEventsAsc } from '../../helpers/Tools';
import theme from '../../theme';
import MyCalendarEventMemo from './MyCalendarEventMemo';

type MyCalendarEventMemoListProps = {
  events: Event[];
  view: View;
  garage: Garage;
  handleSelectEvent: (event: Event) => void;
  openDrawer: boolean
};

const MEMO_EVENTS_SIZE_UNEXPANDED: number = 2;

type ExpandMoreProps = IconButtonProps & {
  expand: boolean;
};
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const MyCalendarEventMemoList = memo(
  ({ events, handleSelectEvent, view, garage, openDrawer }: MyCalendarEventMemoListProps) => {
    const [newEventsExpanded, setNewEventsExpanded] = useState<boolean>(false);
    const [scheduledEventsExpanded, setScheduledEventsExpanded] = useState<boolean>(false);
    const [acceptedEventsExpanded, setAcceptedEventsExpanded] = useState<boolean>(false);

    const getCounterStyle = (eventState: EventState): CSSProperties => {
      return {
        color: getEventColor(eventState),
        //fontFamily: 'Dancing Script, cursive',
        fontWeight: 600,
        fontSize: openDrawer ? 45 : 35,
      };
    };

    const getEventColor = (eventState: EventState) => {
      switch (eventState) {
        case EventState.NEW:
          return theme.palette.primary.main;
        case EventState.SCHEDULED:
          return theme.palette.secondary.main;
        // Correspond à l'état "en attente acceptation du devis"
        case EventState.ACCEPTED:
          return PALETTE_THIRD_COLOR_MAIN;
      }
    };

    const handleEventsMemoExpandClick = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      eventState: EventState,
    ) => {
      e.preventDefault();

      switch (eventState) {
        case EventState.NEW:
          setNewEventsExpanded(!newEventsExpanded);
          break;
        case EventState.SCHEDULED:
          setScheduledEventsExpanded(!scheduledEventsExpanded);
          break;
        case EventState.ACCEPTED:
        case EventState.REJECTED:
          setAcceptedEventsExpanded(!acceptedEventsExpanded);
          break;
      }
    };

    const getEventsMemoExpandedValue = (eventState: EventState): boolean => {
      switch (eventState) {
        case EventState.NEW:
          return newEventsExpanded;
        case EventState.SCHEDULED:
          return scheduledEventsExpanded;
        case EventState.ACCEPTED:
        case EventState.REJECTED:
          return acceptedEventsExpanded;
      }
      return false;
    };

    const getEventsMemoCard = (eventState: EventState, title: string) => {
      const filteredEvents = !events?.length
        ? []
        : events.filter(
            (event) =>
              event.type === EventType.APPOINTMENT &&
              (event.state === eventState ||
                (eventState === EventState.ACCEPTED && event.state === EventState.REJECTED)),
          );

      return (
        <Card variant='outlined' sx={{ width: '100%', border: 'none' }}>
          <CardHeader
            avatar={
              <Avatar variant='square' sx={{ backgroundColor: 'transparent', width: openDrawer ? '50px' : '45px' }}>
                <Typography style={getCounterStyle(eventState)}>{filteredEvents.length}</Typography>
              </Avatar>
            }
            title={
              <Typography variant='body2' sx={{ textAlign: 'center' }}>
                <b>{title ?? 'événements'}</b>
              </Typography>
            }
            action={
              filteredEvents.length > MEMO_EVENTS_SIZE_UNEXPANDED && (
                <ExpandMore
                  sx={{mr: 1}}
                  expand={getEventsMemoExpandedValue(eventState)}
                  onClick={(e) => handleEventsMemoExpandClick(e, eventState)}
                  aria-expanded={getEventsMemoExpandedValue(eventState)}
                  aria-label='Voir plus'
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              )
            }
            sx={{
              paddingLeft: !openDrawer ? 0 : 'auto',
              color: '#464646',
              borderTop: `7px solid ${getEventColor(eventState)}`,
              border: !openDrawer ? `7px solid ${getEventColor(eventState)}` : '',
              padding: openDrawer ? 2 : 0,
              mx: openDrawer ? 0 : 1,
              px: '0 !important',
              height: openDrawer ? 'auto' : '45px',
              width: openDrawer ? '100%' : '45px',
              borderRadius: openDrawer ? '12px' : '40px',
            }}
          />
          <CardContent sx={{ p: 0, display: !openDrawer ? 'none' : 'block' }}>
            {sortEventsAsc(filteredEvents)
              .slice(0, MEMO_EVENTS_SIZE_UNEXPANDED) // Seulement les X premiers événements
              .map((event, index) => (
                <Box
                  sx={{ cursor: 'pointer', mb: index === filteredEvents.length - 1 ? 0 : 1 }}
                  onClick={() => handleSelectEvent(event)}
                >
                  <MyCalendarEventMemo event={event} view={view} />
                </Box>
              ))}
          </CardContent>
          <CardActions disableSpacing sx={{ p: 0 }} />
          <Collapse in={getEventsMemoExpandedValue(eventState)} timeout='auto' unmountOnExit>
            <CardContent sx={{ p: 0 }}>
              {sortEventsAsc(filteredEvents)
                .slice(MEMO_EVENTS_SIZE_UNEXPANDED) // Prenez tous les événements après les X premiers
                .map((event, index) => (
                  <Box
                    sx={{
                      cursor: 'pointer',
                      mb: index === filteredEvents.length - (MEMO_EVENTS_SIZE_UNEXPANDED + 1) ? 0 : 1,
                    }}
                    onClick={() => handleSelectEvent(event)}
                  >
                    <MyCalendarEventMemo event={event} view={view} />
                  </Box>
                ))}
            </CardContent>
            <CardActions disableSpacing sx={{ p: 0 }} />
          </Collapse>
        </Card>
      );
    };

    return (
      <Box sx={{ flexGrow: 1, px: openDrawer ? 1 : 0 }}>
        {' '}
        {/* Cette Box prendra tout l'espace vertical disponible */}
        <List dense>
          <ListItem key={1} disablePadding>
            {getEventsMemoCard(EventState.NEW, 'Rendez-vous à confirmer')}
          </ListItem>

          <ListItem key={2} disablePadding sx={{ mt: 2 }}>
            {getEventsMemoCard(EventState.SCHEDULED, 'Dispo pièces à confirmer')}
          </ListItem>

          {garage?.customerQuoteActive && (
            <ListItem key={3} disablePadding sx={{ mt: 2 }}>
              {getEventsMemoCard(EventState.ACCEPTED, 'Devis client en attente')}
            </ListItem>
          )}
        </List>
      </Box>
    );
  },
);
