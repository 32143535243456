import {
  ConfirmationDialog,
  type Customer,
  MovaVehicleTireField,
  type Vehicle,
  formatFrenchVehiclePlate,
} from '@movalib/movalib-commons';
import { formatVehicleTire } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import CloseIcon from '@mui/icons-material/CloseRounded';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import invariant from 'invariant';
import { noop } from 'lodash';
import { memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import CarCounter from '../../assets/images/car_counter.png';
import CatPlateBg from '../../assets/images/car_plate_bg.png';
import TirePicture from '../../assets/images/flanc_pneu.png';
import { flexCenterCol } from '../../helpers/Tools';
import { useBoolState } from '../../helpers/hooks/useBoolState';
import { useFetchAdministratedGarages, useGarageDeleteCustomerVehicle } from '../../query/garage/GarageQuery';
import { usePatchVehicleInfos } from '../../query/vehicle/VehicleQuery';
import { setSnackbar } from '../../slices/snackbarSlice';
import theme from '../../theme';

const editVehicleSchema = Yup.object({
  currentMileage: Yup.number().default(0).min(0),
  averageMileagePerYear: Yup.number().default(0).min(0),
  tireInfos: Yup.object({
    width: Yup.string().length(3).required(),
    height: Yup.string().length(2).required(),
    diameter: Yup.string().length(3).required(),
    speedIndex: Yup.string().length(3).required(),
  }),
});

type EditVehicleForm = Yup.InferType<typeof editVehicleSchema>;

type EditVehicleProps = {
  open: boolean;
  onClose: () => void;
  customer: Customer;
  vehicle: Vehicle;
};

export const EditVehicle = memo(({ open, onClose, customer, vehicle }: EditVehicleProps) => {
  const dispatch = useDispatch();
  const [vehicleError, setVehicleError] = useState<string | undefined>(undefined);
  const { isConfirmDeleteVehicleDialogOpen, toggleConfirmDeleteVehicleDialogOpen } = useBoolState(
    false,
    'confirmDeleteVehicleDialogOpen',
  );

  const { data: garage } = useFetchAdministratedGarages();
  const { mutateAsync: patchVehicle } = usePatchVehicleInfos();
  const { mutateAsync: deleteCustomerVehicle } = useGarageDeleteCustomerVehicle();

  const { values, handleChange, setFieldValue } = useFormik<EditVehicleForm>({
    initialValues: {
      currentMileage: vehicle.currentMileage ?? 0,
      averageMileagePerYear: vehicle.averageMileagePerYear ?? 0,
      tireInfos: {
        width: vehicle.tireWidth ?? '',
        height: vehicle.tireHeight ?? '',
        diameter: vehicle.tireDiameter ?? '',
        speedIndex: vehicle.tireSpeedIndex ?? '',
      },
    },
    validationSchema: editVehicleSchema,
    onSubmit: noop,
    enableReinitialize: true,
  });

  const onChangeTireInfosProxy = useCallback(
    (tireInfos: EditVehicleForm['tireInfos']) => {
      setFieldValue('tireInfos', tireInfos);
    },
    [setFieldValue],
  );

  const onDeleteVehicle = useCallback(async () => {
    invariant(garage?.id, 'Garage ID is not defined');

    const response = await deleteCustomerVehicle({
      garageId: garage.id,
      vehicleId: `${vehicle.id}`,
      customerId: customer.id,
    });

    if (response.success) {
      dispatch(
        setSnackbar({
          open: true,
          message: response.data ?? 'Véhicule supprimé du fichier client !',
          severity: 'success',
        }),
      );
      onClose();
    } else {
      dispatch(
        setSnackbar({
          open: true,
          message: response.error ?? 'Erreur lors de la suppression du véhicule',
          severity: 'error',
        }),
      );
    }
  }, [customer.id, deleteCustomerVehicle, dispatch, garage?.id, onClose, vehicle.id]);

  const onSubmitForm = useCallback(async () => {
    const { averageMileagePerYear, currentMileage, tireInfos } = values;
    const formattedTireInfo = formatVehicleTire(tireInfos).replace(/[^0-9A-QS-Za-qs-z]/g, '');

    if (
      (formattedTireInfo.length !== 0 && formattedTireInfo.length < 10) ||
      (formattedTireInfo.length === 10 && !new RegExp(/^\d{9}[A-Za-z]$/).test(formattedTireInfo))
    ) {
      setVehicleError('Informations de pneus invalides');
      return;
    }
    setVehicleError(undefined);

    // Send to API
    const response = await patchVehicle({
      vehicleId: vehicle.id,
      currentMileage,
      averageMileagePerYear,
      tireWidth: tireInfos.width || undefined,
      tireHeight: tireInfos.height || undefined,
      tireDiameter: tireInfos.diameter || undefined,
      tireSpeedIndex: tireInfos.speedIndex || undefined,
    });

    if (response.success) {
      dispatch(
        setSnackbar({
          open: true,
          message: response.data ?? 'Véhicule ajouté au fichier client !',
          severity: 'success',
        }),
      );
      onClose();
    } else {
      dispatch(
        setSnackbar({ open: true, message: response.error ?? "Erreur lors de l'ajout du véhicule", severity: 'error' }),
      );
    }
  }, [dispatch, onClose, patchVehicle, values, vehicle.id]);

  return (
    <>
      <Dialog open={open} maxWidth='sm' scroll='body' onClose={onClose} fullWidth>
        <Toolbar
          disableGutters
          variant='dense'
          sx={{
            minHeight: 3,
            backgroundColor: theme.palette.grey[200],
            py: 0,
          }}
        >
          <DialogTitle sx={{ flexGrow: 1 }} component={'div'}>
            <Typography
              py={0}
              my={0}
              sx={{
                pl: '34px',
                color: theme.palette.text.primary,
                textAlign: 'center',
              }}
            >
              <span>
                MODIFIER <b>UN VÉHICULE</b>
              </span>
            </Typography>
          </DialogTitle>
          <Tooltip title='Fermer'>
            <IconButton sx={{ mr: 1 }} size='small' aria-label='close' onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>

        <Typography
          variant='h5'
          color={theme.palette.primary.main}
          sx={{ fontWeight: 'bold', textAlign: 'center', p: 2 }}
        >
          {`${vehicle.brand} ${vehicle.model} ${vehicle.version}`}
        </Typography>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={8} sx={{ position: 'relative' }} justifyContent='center'>
              <img
                src={CatPlateBg}
                alt="Plaque d'immatriculation"
                style={{ height: '50px', position: 'relative', marginLeft: '30px' }}
              />
              <Typography
                variant='h6'
                color={theme.palette.text.primary}
                sx={{ position: 'absolute', top: '25px', left: '120px' }}
              >
                <b>{formatFrenchVehiclePlate(vehicle.plate)}</b>
              </Typography>
            </Grid>
            <Grid item xs={4} alignContent='center'>
              <Button variant='contained' color='error' onClick={toggleConfirmDeleteVehicleDialogOpen}>
                Supprimer
              </Button>
            </Grid>
            <Grid item xs={1} style={flexCenterCol}>
              <img
                src={CarCounter}
                style={{
                  position: 'relative',
                  width: '100%',
                  opacity: 0.4,
                  zIndex: 200,
                }}
                alt='Icone Pneumatique'
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                type='number'
                fullWidth
                margin='normal'
                id='currentMileage'
                label='Kilométrage actuel'
                name='currentMileage'
                value={values.currentMileage}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin='normal'>
                <InputLabel id='averageMileagePerYear-label'>Kilométrage moyen annuel</InputLabel>
                <Select
                  labelId='averageMileagePerYear-label'
                  id='averageMileagePerYear'
                  name='averageMileagePerYear'
                  value={values.averageMileagePerYear}
                  onChange={handleChange}
                  label='Kilométrage moyen annuel'
                >
                  <MenuItem value={5000}>5 000</MenuItem>
                  <MenuItem value={10000}>10 000</MenuItem>
                  <MenuItem value={15000}>15 000</MenuItem>
                  <MenuItem value={20000}>20 000</MenuItem>
                  <MenuItem value={25000}>25 000</MenuItem>
                  <MenuItem value={30000}>30 000</MenuItem>
                  <MenuItem value={50000}>50 000</MenuItem>
                  <MenuItem value={75000}>75 000</MenuItem>
                  <MenuItem value={100000}>100 000</MenuItem>
                  <MenuItem value={999999}>+100 000</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} style={flexCenterCol}>
              <img
                src={TirePicture}
                style={{
                  position: 'relative',
                  width: '100%',
                  opacity: 0.4,
                  zIndex: 200,
                }}
                alt='Icone Pneumatique'
              />
            </Grid>
            <Grid item xs={11}>
              <MovaVehicleTireField onChangeVehicleTire={onChangeTireInfosProxy} vehicleTire={values.tireInfos} />
            </Grid>
          </Grid>
          {!!vehicleError && (
            <Alert severity='error' sx={{ mt: 2, mb: 1 }}>
              {vehicleError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button variant='contained' color='inherit' onClick={onClose}>
            Annuler
          </Button>
          <Button variant='contained' color='primary' onClick={onSubmitForm}>
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={isConfirmDeleteVehicleDialogOpen}
        onClose={toggleConfirmDeleteVehicleDialogOpen}
        onConfirm={onDeleteVehicle}
        title='Supprimer le véhicule'
        message='Êtes-vous sûr de vouloir supprimer le véhicule assigné à ce client ?'
      />
    </>
  );
});
