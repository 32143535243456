// serviceWorkerRegistration.js

// Fonction pour enregistrer le Service Worker
export const register = () => {
  // Vérifiez si le Service Worker et les Promises sont supportés
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/serviceWorker.js')
        .then(registration => {
          console.log('Service Worker enregistré avec succès : ', registration.scope);
           registration.addEventListener('updatefound', function() {
            // Une mise à jour du service worker est trouvée
            console.log('Une mise à jour du service worker est trouvée');
            const newWorker = registration.installing;
            newWorker.onstatechange = function() {
              if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
                // Afficher une alerte (Snackbar) ici, par exemple en utilisant un événement personnalisé
                document.dispatchEvent(new CustomEvent("swUpdateFound"));
              }
            };
          });
        })
        .catch(error => {
          console.error('Échec de l’enregistrement du Service Worker : ', error);
        });
    });
  }
};

// Fonction pour désenregistrer le Service Worker
export const unregister = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations()
      .then(registrations => {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
  }
};