import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MyAppBar from "../components/MyAppBar";
import MyCalendar from "../components/calendar/MyCalendar";
import { Avatar, Box, Container, Divider, Drawer, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, lighten, useTheme } from "@mui/material";
import { flexCenterRow } from "../helpers/Tools";

interface AgendaProps {
    garageId: string;
  }

//xs, sm, md, lg, and xl
const Agenda: FunctionComponent<AgendaProps> = ({ garageId }) => {

    return (
        // 72px height of the toolbar
        <Box sx={{ pt: '72px', height: 'calc(100vh - 72px)' }}>
            <MyCalendar garageId={garageId}/>
        </Box>
    );
};

export default Agenda;

