import { validatePhoneNumber } from '@movalib/movalib-commons';
import { Alert, DialogContent, Grid, TextField, type TextFieldProps } from '@mui/material';
import type { FormikErrors, FormikHandlers, FormikHelpers } from 'formik';
import { startCase } from 'lodash';
import { memo, useCallback } from 'react';
import type { Form } from './CustomerDialog';

type UpsertCustomerProps = {
  values: Form;
  errors: FormikErrors<Form>;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: FormikHelpers<Form>['setFieldValue'];
};

export const UpdateCustomer = memo(({ values, errors, handleChange, setFieldValue }: UpsertCustomerProps) => {
  const handleChangeLastname = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setFieldValue('lastname', e.target.value.toUpperCase());
    },
    [setFieldValue],
  );

  const handleChangeFirstName = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      // uppercase each 1rst letter on each word (Just Like This You Know)
      const words = e.target.value.split(/(\s+|-+)/g).map((word) => {
        // Only capitalize words that are not spaces or hyphens
        if (word.trim() === '' || word === '-') {
          return word;
        }
        return startCase(word.toLowerCase());
      });

      setFieldValue('firstname', words.join(''));
    },
    [setFieldValue],
  );

  const handleChangePhoneNumber = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      if (e.target.value !== '' && !validatePhoneNumber(e.target.value)) {
        return;
      }
      setFieldValue('phoneNumber', e.target.value);
    },
    [setFieldValue],
  );

  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              margin='normal'
              autoFocus
              required
              fullWidth
              id='lastname'
              label='NOM'
              name='lastname'
              autoComplete='family-name'
              onChange={handleChangeLastname}
              value={values.lastname}
              error={!!errors.lastname}
              helperText={errors.lastname}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='firstname'
              label='Prénom'
              name='firstname'
              autoComplete='given-name'
              onChange={handleChangeFirstName}
              value={values.firstname}
              error={!!errors.firstname}
              helperText={errors.firstname}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type='tel'
              margin='normal'
              fullWidth
              required
              id='phoneNumber'
              label='N° de téléphone'
              name='phoneNumber'
              autoComplete='tel'
              onChange={handleChangePhoneNumber}
              value={values.phoneNumber}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
            />
          </Grid>
          <Grid item xs={6}>
            <Alert sx={{ height: '100%' }}>
              L'email est facultatif, mais on vous encourage à le demander pour réduire le papier 🙏🌿 !
            </Alert>
          </Grid>

          <Grid item xs={12}>
            <TextField
              margin='normal'
              fullWidth
              id='email'
              label='Adresse email'
              name='email'
              autoComplete='email'
              onChange={handleChange}
              value={values.email}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
});
