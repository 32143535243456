// useDarkMode.tsx

import { useEffect, useState } from 'react';

const useDarkMode = (): boolean => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => setIsDarkMode(mediaQuery.matches);

    // Utilisation de addEventListener
    mediaQuery.addEventListener('change', handleChange);

    // Nettoyage avec removeEventListener
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  return isDarkMode;
}

export default useDarkMode;
