import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import type React from 'react';
import type { FunctionComponent } from 'react';
import { flexLeftRow } from '../helpers/Tools';

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onClickBtnClose?: () => void;
  onConfirm?: () => void;
  showConfirm?: boolean;
  confirmLabel?: string;
  closeLabel?: string;
  title?: React.ReactNode;
  message?: React.ReactNode;
  confirmBtnComponent?: React.ReactNode;
}
/**
 * Ce composant est encore pleinement fonctionnel,
 * Cependant, merci de privilégier l'utilisation du composant depuis le lib @movalib/movalib-commons
 * @deprecated
 */
const ConfirmationDialog: FunctionComponent<ConfirmationDialogProps> = ({
  open,
  onClose,
  onClickBtnClose,
  onConfirm,
  title,
  message,
  showConfirm = true,
  confirmLabel,
  closeLabel,
  confirmBtnComponent,
}) => {
  const theme = useTheme();
  const defaultMessage = 'Êtes-vous sûr de vouloir continuer ?';
  const defaultTitle = 'Confirmation';
  const defaultConfirmLabel = 'Oui';
  const defaultCloseLabel = 'Non';

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle
        id='alert-dialog-title'
        sx={{
          backgroundColor: theme.palette.grey[200],
        }}
        style={flexLeftRow}
      >
        {title || defaultTitle}
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <DialogContentText id='alert-dialog-description' sx={{ pt: 2 }}>
          {message || defaultMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          py: 1,
          backgroundColor: theme.palette.grey[200],
        }}
      >
        <Button onClick={onClickBtnClose || onClose} color='inherit' sx={{ mr: 1 }}>
          {closeLabel || defaultCloseLabel}
        </Button>
        {showConfirm && (
          <>
            {confirmBtnComponent || (
              <Button onClick={onConfirm} color='inherit' autoFocus sx={{ ml: 1 }}>
                <b>{confirmLabel || defaultConfirmLabel}</b>
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
