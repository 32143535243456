import {
  type DaySchedule,
  GarageService,
  Logger,
  type Prestation,
  PrestationState,
  Schedule,
  ScheduleFields,
  capitalizeFirstLetter,
} from '@movalib/movalib-commons';
import { flexCenter, validateField } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import CloseIcon from '@mui/icons-material/CloseRounded';
import PrevOutlined from '@mui/icons-material/NavigateBeforeOutlined';
import NextOutlined from '@mui/icons-material/NavigateNextOutlined';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  type SelectChangeEvent,
  TextField,
  ToggleButton,
  Toolbar,
  Tooltip,
  Typography,
  darken,
} from '@mui/material';
import { format } from 'date-fns';
import type React from 'react';
import { type CSSProperties, type FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AvatarSelector from '../components/AvatarSelector';
import type { FormField } from '../helpers/Constants';
import { StyledToggleButtonGroup } from '../helpers/Styled';
import { flexEnd, importIcon } from '../helpers/Tools';
import { validateName } from '../helpers/Validator';
import { useFetchAdministratedGarages } from '../query/garage/GarageQuery';
import { setSnackbar } from '../slices/snackbarSlice';
import theme from '../theme';
import './AddEmployeeDialog.css';

interface AddEmployeeDialogProps {
  open: boolean;
  onClose: (refresh: boolean) => void;
}

enum Step {
  PRESTATIONS = 0,
  SCHEDULES = 1,
}

type Form = {
  avatar: FormField;
  firstname: FormField;
  lastname: FormField;
  phoneNumber: FormField;
  prestations: FormField;
  schedules: FormField;
};

const initialFormState = {
  avatar: { value: '', valid: true },
  firstname: { value: '', valid: true },
  lastname: { value: '', valid: true },
  phoneNumber: { value: '', valid: true },
  prestations: { value: [], isValid: true },
  schedules: { value: [], isValid: true },
};

const AddEmployeeDialog: FunctionComponent<AddEmployeeDialogProps> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  // Fetch les infos du garage dynamiquement selon la demande
  const { data: garage } = useFetchAdministratedGarages();

  const [form, setForm] = useState<Form>(initialFormState);
  const [prestations, setPrestations] = useState<Prestation[]>([]);
  const [currentStep, setCurrentStep] = useState<Step>(Step.PRESTATIONS);

  useEffect(() => {
    // Définition des horaires de travail par défaut sur celle du garage
    setForm((prevForm) => ({ ...prevForm, ['schedules']: { ...prevForm['schedules'], value: garage?.schedules } }));
  }, [garage]);

  const handleAvatarChange = (avatar: string | null) => {
    setForm((prevForm) => ({ ...prevForm, ['avatar']: { ...prevForm['avatar'], value: avatar ?? '' } }));
  };

  const purgeLocalState = () => {
    setForm(initialFormState);
  };

  const handleOnClose = (reason: string) => {
    if (reason === 'backdropClick') return;

    // On purge le state local avant d'appeler le callback de fermeture
    purgeLocalState();
    onClose(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const fieldName: string = e.target.name;
    let fieldValue: string = e.target.value;

    // Capitalisation automatique du prénom
    if (fieldName === 'firstname') {
      fieldValue = capitalizeFirstLetter(fieldValue);
    }

    // Uppercase automatique pour le nom
    if (fieldName === 'lastname') {
      fieldValue = fieldValue.toUpperCase();
    }

    const newField: FormField = { [fieldName]: { value: fieldValue, isValid: true } };

    setForm({ ...form, ...newField });
  };

  const handleSelectChange = (e: SelectChangeEvent<string>): void => {
    const fieldName: string = e.target.name;
    const fieldValue: string = e.target.value;
    const newField: FormField = { [fieldName]: { value: fieldValue, isValid: true } };

    setForm({ ...form, ...newField });
  };

  const validateForm = () => {
    let newForm: Form = form;
    let newField: FormField;

    // Validator pour les champs obligatoires
    newForm.prestations = validateField(
      newForm.prestations,
      (value) => !!value,
      'Vous devez choisir au moins une prestation',
    );

    if (Array.isArray(newForm.prestations.value) && newForm.prestations.value.length === 0) {
      newField = {
        value: form.prestations.value,
        error: 'Vous devez choisir au moins une prestation.',
        isValid: false,
      };
      newForm = { ...newForm, ...{ prestations: newField } };
    }

    // Validator 'firstname'
    if (!validateName(newForm.firstname.value)) {
      newField = { value: form.firstname.value, error: 'Le prénom est invalide.' };
    } else {
      newField = { value: form.firstname.value, error: '' };
    }
    newForm = { ...newForm, ...{ firstname: newField } };

    // Validator 'lastname'
    if (!validateName(newForm.lastname.value)) {
      newField = { value: form.lastname.value, error: 'Le nom est invalide.' };
    } else {
      newField = { value: form.lastname.value, error: '' };
    }
    newForm = { ...newForm, ...{ lastname: newField } };

    setForm(newForm);

    return !Boolean(newForm.firstname.error || newForm.lastname.error || newForm.prestations.error);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    switch (currentStep) {
      case Step.PRESTATIONS:
        if (validateForm()) {
          setCurrentStep(Step.SCHEDULES);
        }
        return;
      case Step.SCHEDULES:
        // Validation du formulaire
        if (validateForm()) {
          if (garage) {
            const req = {
              avatar: form.avatar?.value,
              firstname: form.firstname.value,
              lastname: form.lastname.value,
              prestations: form.prestations.value,
              schedules: form.schedules.value,
            };

            GarageService.createGarageEmployee(garage.id, req)
              .then((response) => {
                Logger.info(response);

                if (response.success) {
                  // Fermeture de la boite de dialogue (avec refresh des employés)
                  purgeLocalState();
                  onClose(true);
                  dispatch(
                    setSnackbar({
                      open: true,
                      message: response.data ?? 'Le technicien a bien été ajouté',
                      severity: 'success',
                    }),
                  );
                } else {
                  dispatch(
                    setSnackbar({
                      open: true,
                      message: response.error ?? "Erreur lors de l'ajout du réparateur",
                      severity: 'error',
                    }),
                  );
                }
              })
              .catch((error) => {
                console.error(error);
              });
          } else {
            // Affichage notification utilisateur
            dispatch(setSnackbar({ open: true, message: 'Données garage non disponibles', severity: 'error' }));
          }
        }
    }
  };

  const handlePrevStep = () => {
    setCurrentStep(Step.PRESTATIONS);
  };

  const handlePrestations = (event: React.MouseEvent<HTMLElement>, newPrestations: Prestation[]) => {
    if (newPrestations.length) {
      setForm((prevForm) => ({ ...prevForm, ['prestations']: { ...prevForm['prestations'], value: newPrestations } }));
    } else {
      setForm((prevForm) => ({ ...prevForm, ['prestations']: { ...prevForm['prestations'], value: [] } }));
    }
  };

  const handleScheduleChange = (schedules: DaySchedule[]) => {
    if (schedules) {
      // On contrôle l'absence d'erreur dans le tableau de schedule
      const hasErrors = schedules.some((day) => day.intervals.some((interval) => interval.error !== null));

      Logger.info(schedules);

      if (!hasErrors) {
        // On crée un objet Schedule sur la base du DaySchedule reçu de ScheduleFields
        const newSchedules = new Array<Schedule>();
        schedules.forEach((s) => {
          // Seuls les journées actives (cochées) sont envoyées
          if (s.checked) {
            newSchedules.push(
              new Schedule(
                s.day,
                s.intervals.map(({ startTime, endTime, countryCode }) => ({
                  startTime: startTime ? format(new Date(startTime), 'HH:mm') : null,
                  endTime: endTime ? format(new Date(endTime), 'HH:mm') : null,
                  countryCode,
                })),
                true,
              ),
            );
          }
        });

        const request = {
          schedules: newSchedules,
        };

        setForm((prevForm) => ({ ...prevForm, ['schedules']: { ...prevForm['schedules'], value: newSchedules } }));
      }
    }
  };

  const handleSelectAllPrestations = () => {
    if (garage?.prestations) {
      const allPrestations = garage.prestations
        .filter((p) => p.state === PrestationState.VALIDATED && p.active)
        .filter((p) => p.code !== 'OTHER')
        .map((p) => p.id);
      setForm((prevForm) => ({ ...prevForm, ['prestations']: { ...prevForm['prestations'], value: allPrestations } }));
    }
  };

  const prestationStyle: CSSProperties = {
    fontSize: 12,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    margin: 3,
    padding: 3,
  };

  return (
    <Dialog open={open} onClose={(event, reason) => handleOnClose(reason)} fullWidth maxWidth='md' scroll='body'>
      <form onSubmit={handleSubmit}>
        <Toolbar
          disableGutters
          variant='dense'
          sx={{
            minHeight: 3,
            backgroundColor: theme.palette.grey[200],
            py: 0,
          }}
        >
          <DialogTitle sx={{ flexGrow: 1 }} component={'div'}>
            <Typography
              py={0}
              my={0}
              sx={{
                pl: '34px',
                color: theme.palette.text.primary,
                textAlign: 'center',
              }}
            >
              <span>
                AJOUTER&nbsp;<b>UN RÉPARATEUR</b>
              </span>
            </Typography>
          </DialogTitle>
          <Tooltip title='Fermer'>
            <IconButton sx={{ mr: 1 }} size='small' aria-label='close' onClick={() => handleOnClose('')}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <DialogContent>
          <Grid container>
            <Grid item xs={4} style={flexCenter}>
              <AvatarSelector
                firstName={form.firstname.value}
                lastName={form.lastname.value}
                onChange={handleAvatarChange}
              />
            </Grid>

            <Grid item xs={7}>
              <TextField
                margin='normal'
                required
                autoFocus
                size='small'
                fullWidth
                id='firstname'
                label='Prénom'
                name='firstname'
                autoComplete='given-name'
                onChange={(e) => handleInputChange(e)}
                value={form.firstname.value}
                error={Boolean(form.firstname.error)}
                helperText={form.firstname.error}
              />
              <TextField
                margin='normal'
                required
                fullWidth
                size='small'
                id='lastname'
                label='Nom'
                name='lastname'
                autoComplete='family-name'
                onChange={(e) => handleInputChange(e)}
                value={form.lastname.value}
                error={Boolean(form.lastname.error)}
                helperText={form.lastname.error}
              />
            </Grid>

            {currentStep === Step.PRESTATIONS && (
              <Grid item xs={12}>
                <Box display='flex' alignItems='center' mb={2} mt={3}>
                  <Typography>
                    <b>Choisissez le(s) prestation(s) que le réparateur est capable de réaliser</b>
                  </Typography>
                </Box>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      color={darken(theme.palette.primary.main, 0.1)}
                      sx={{ fontFamily: '', fontSize: '1rem', mb: 1 }}
                    >
                      <b>{(form.prestations.value as Prestation[]).length}</b>&nbsp;&nbsp;prestations sélectionnées
                    </Typography>
                  </Grid>

                  <Grid item xs={6} style={flexEnd}>
                    <Button
                      variant='outlined'
                      size='small'
                      sx={{ fontSize: '0.9rem', mb: 1, color: darken(theme.palette.primary.main, 0.1) }}
                      onClick={handleSelectAllPrestations}
                    >
                      TOUT SÉLECTIONNER
                    </Button>
                  </Grid>
                </Grid>
                <Box style={flexCenter} mb={1}>
                  <StyledToggleButtonGroup
                    value={form.prestations.value}
                    onChange={handlePrestations}
                    aria-label='device'
                    sx={{ p: 2, ml: 0, mr: 0 }}
                  >
                    {garage?.prestations &&
                      (() => {
                        // Uniquement les prestations actives
                        const prestations = [...garage.prestations]
                          .filter((p) => p.state === PrestationState.VALIDATED && p.active)
                          // Sans la prestation autre qui sera active par défaut
                          .filter((p) => p.code !== 'OTHER');

                        // Trier par ordre alphabétique
                        const sortedPrestations = prestations.sort((a, b) => a.name.localeCompare(b.name));

                        Logger.info(prestations);

                        const items = [
                          // Ajouter les 10 premières prestations triées par position
                          ...sortedPrestations.map((prestation) => (
                            <ToggleButton key={prestation.id} value={prestation.id} style={prestationStyle}>
                              {/* Affichage d'un picto si défini */}
                              {importIcon(prestation.code) && (
                                <img
                                  src={importIcon(prestation.code)}
                                  style={{ width: '35px', height: '35px', margin: 5, opacity: 0.8 }}
                                />
                              )}
                              {prestation.name}
                            </ToggleButton>
                          )),
                        ];

                        return <div className='my-grid-container'>{items}</div>;
                      })()}
                  </StyledToggleButtonGroup>
                </Box>
                <FormHelperText sx={{ color: theme.palette.error.main, mt: 1, mb: 1 }}>
                  {form.prestations.error}
                </FormHelperText>
              </Grid>
            )}

            {currentStep === Step.SCHEDULES && (
              <Grid item xs={12}>
                <Box display='flex' alignItems='center' mb={3} mt={3}>
                  <Typography>
                    <b>Quel sont ses horaires de travail ?</b> &nbsp;(horaires garage indiqués par défaut)
                  </Typography>
                </Box>
                <ScheduleFields
                  schedules={form.schedules.value}
                  size='small'
                  onChange={handleScheduleChange}
                  timePickerStep={30}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <DialogActions sx={{ borderTop: 1, borderColor: theme.palette.grey[200], mt: 1, mb: 1, mr: 1 }}>
          {currentStep === Step.SCHEDULES && (
            <Button
              variant='contained'
              color='inherit'
              onClick={handlePrevStep}
              sx={{ mr: 2 }}
              startIcon={<PrevOutlined />}
            >
              Précédent
            </Button>
          )}
          <Button
            type='submit'
            variant='contained'
            startIcon={currentStep === Step.PRESTATIONS ? <NextOutlined /> : <SaveOutlined />}
          >
            {currentStep === Step.PRESTATIONS ? 'Suivant' : 'Enregistrer'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddEmployeeDialog;
