export enum CalendarView {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
  EMPLOYEES = 'EMPLOYEES',
}

export enum VehicleDepositPreference {
  SAME_DAY = 'SAME_DAY',
  DAY_BEFORE = 'DAY_BEFORE',
}

export enum StripeClientSecretType {
  SETUP_INTENT = 'SETUP_INTENT',
  PAYMENT_INTENT = 'PAYMENT_INTENT',
}

export enum SubscriptionType {
  PRO_FREE = 'PRO_FREE',
  PRO_STANDARD = 'PRO_STANDARD',
}

export enum SubscriptionState {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  CANCELED = 'CANCELED',
}

export enum PrestationCode {
  SIMPLE_OIL_CHANGE = 'SIMPLE_OIL_CHANGE',
  FULL_OIL_CHANGE = 'FULL_OIL_CHANGE',
  MAINTENANCE = 'MAINTENANCE',
  TIRE = 'TIRE',
  FLAT = 'FLAT',
  GEOMETRY = 'GEOMETRY',
  BRAKE_PADS = 'BRAKE_PADS',
  BRAKE_PADS_DISC = 'BRAKE_PADS_DISC',
  TIMING_BELT = 'TIMING_BELT',
  ACCESSORY_BELT = 'ACCESSORY_BELT',
  DIAGNOSTIC = 'DIAGNOSTIC',
  AD_BLUE = 'AD_BLUE',
  KEY = 'KEY',
  OTHER = 'OTHER',
}

export enum SupplierRequestType {
  QUOTE = 'QUOTE',
  ORDER = 'ORDER',
}

export enum DocumentType {
  INVOICE = 'INVOICE',
  TYRE_PICTURE = 'TYRE_PICTURE',
  CAR_PICTURE = 'CAR_PICTURE',
}

export enum APIMethod {
  GET = 'GET',
  PUT = 'PUT',
  PATCH = 'PATCH',
  POST = 'POST',
  DELETE = 'DELETE',
}

export enum ActionType {
  DELETE = 'DELETE',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  NONE = 'NONE',
}

export enum RoleType {
  /**
   * Client du centre auto (automobiliste)
   */
  CUSTOMER = 'CUSTOMER',
  /**
   * Technicien du centre auto
   */
  TECHNICIAN = 'GARAGE_LEADER',
  /**
   * Chef d'atelier
   */
  GARAGE_LEADER = 'GARAGE_LEADER',
  /**
   * Administrateur du centre auto (propriétaire ou dirigeant)
   */
  GARAGE_ADMIN = 'ADMINISTRATOR',
  /**
   * Super-Administrateur (team Movalib)
   */
  MOVALIB_ADMIN = 'MOVALIB_ADMIN',
}

export enum PeriodType {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  DAY = 'DAY',
}

export enum ButtonType {
  NORMAL = 'NORMAL',
  SUBMIT = 'SUBMIT',
}

export enum EventState {
  /**
   * Nouvelle demande de rendez-vous (origine client OU centre)
   */
  NEW = 'NEW',
  /**
   * Accepté (rendez-vous accepté par le centre)
   * Note : il s'agit du statut par défaut en cas de création du rendez-vous par le centre
   */
  ACCEPTED = 'ACCEPTED',
  /**
   * Planifé (rendez-vous planifié, devis accepté par le client le cas échéant)
   * Note : si l'acceptation du devis n'est pas obligatoire pour le centre,
   * le rendez-vous passe directement à l'état 'SCHEDULED' lorsqu'il est accepté
   */
  SCHEDULED = 'SCHEDULED',
  /**
   * Complété (les pièces sont disponibles, état de validation supplémentaire indicatif pour le centre)
   */
  COMPLETED = 'COMPLETED',
  /**
   * Le rendez-vous est terminé
   */
  DONE = 'DONE',
  /**
   * Le rendez-vous a été annulé (par le centre ou le client)
   */
  CANCELLED = 'CANCELLED',
}

export enum CalendarEventType {}

/**
 * Tableau reprenant la liste des routes privées.
 * APP.tsx : On l'utilise pour savoir si nous devons afficher
 * la barre de menu et identifier le menu actif.
 */
export const PrivateRoutesEnum = Object.freeze({
  ROOT: '/',
  AGENDA: '/agenda',
  TEAM: '/team',
  CUSTOMERS: '/customers',
  LOGOUT: '/logout',
  SETTINGS: '/settings',
  SUPPORT: '/support',
  SIGNUP: '/signup',
  REGISTRATION: '/registration',
});

/**
 * Tableau reprenant la liste des entrées de menu de la barre de navigation globale.
 */
export const AppBarPagesEnum = Object.freeze({
  AGENDA: 'AGENDA',
  CUSTOMERS: 'CLIENTS',
  TEAM: 'RÉPARATEURS',
});

/**
 * Tableau reprenant la liste des entrées de menu de settings (clic sur l'avatar)
 */
export const AppBarSettingsEnum = Object.freeze({
  SETTINGS: 'Configuration',
  //SUPPORT:   "Aide",
  LOGOUT: 'Se déconnecter',
});
